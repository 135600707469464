import { FC } from 'react'
import Icon from '@ant-design/icons'
import {
  SeederIcon,
  ThirdGenSeederIcon,
  useIsThirdGen,
} from '@signifyd/components'
import styles from './SeederLabel.less'

interface Props {
  label: string
  dataTestId?: string
}

const SeederLabel: FC<Props> = ({ label, dataTestId }) => {
  const isThirdGen = useIsThirdGen()

  return (
    <>
      <span data-test-id={dataTestId}>{label}</span>
      <Icon
        data-test-id="seederLabelIcon"
        component={isThirdGen ? ThirdGenSeederIcon : SeederIcon}
        className={styles.icon}
      />
    </>
  )
}

export default SeederLabel
