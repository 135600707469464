import { FC, ReactNode, memo, useState } from 'react'
import { Card, Col, Button, Flex } from 'antd'
import {
  CircleDownDuoTone,
  CircleMinusDuoTone,
  CircleUpDuoTone,
  Space,
  TextThirdGen,
} from '@signifyd/components'
import { CaseAnalysisItem } from '@signifyd/http'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import { colorEmerald } from '@signifyd/colors'
import { colorRed } from '@signifyd/colors'
import { colorYonder } from '@signifyd/colors'
import { spacingMD, spacingSM } from '@signifyd/ant'
import styles from './SummaryPanel.less'

type Meaning = 'GOOD' | 'BAD' | 'NEUTRAL'

interface AnalysisDetails {
  itemName: string
  meaning: Meaning
  shortDescription: string
}

const getIcon = (meaning: Meaning): ReactNode => {
  switch (meaning) {
    case 'GOOD':
      return <CircleUpDuoTone fill={colorEmerald} />
    case 'BAD':
      return <CircleDownDuoTone fill={colorRed} />
    case 'NEUTRAL':
    default:
      return <CircleMinusDuoTone fill={colorYonder} />
  }
}

function renderSummaryItems(items: Array<AnalysisDetails>): ReactNode {
  return (
    <Flex
      vertical
      gap={spacingMD}
      data-test-id="summaryList"
      className={styles.summaryList}
    >
      {items.map(({ shortDescription, itemName, meaning }) => {
        const Icon = getIcon(meaning)

        return (
          <Flex
            align="center"
            gap={spacingSM}
            key={itemName}
            data-test-id="summaryItem"
          >
            {Icon}
            <div>
              <TextThirdGen>{shortDescription}</TextThirdGen>
            </div>
          </Flex>
        )
      })}
    </Flex>
  )
}

interface Props {
  title: string
  analysis?: CaseAnalysisItem
  isLarge: boolean
  actions?: Array<ReactNode>
}

export const SummaryPanel: FC<Props> = ({ isLarge, title, analysis }) => {
  const [showMore, setShowMore] = useState(false)
  const { t } = useTranslation()

  const items = analysis
    ? analysis.details.filter(({ shortDescription }) => !!shortDescription)
    : []

  const first3Items = items.splice(0, 3)

  return (
    <Col
      className={cx([styles.summaryPanel, isLarge && styles.large])}
      data-test-id={`summaryPanel${title}`}
    >
      <Card
        styles={{ body: { height: '100%' } }}
        className={styles.content}
        loading={!analysis}
        size="small"
        title={title}
      >
        {!first3Items.length && (
          <span data-test-id="noAnalysis">
            {t('caseIntelligence.noAnalysis')}
          </span>
        )}
        {analysis && renderSummaryItems(first3Items as Array<AnalysisDetails>)}

        {!!items.length &&
          (showMore ? (
            <>
              <Space size={spacingMD} />
              {renderSummaryItems(items as Array<AnalysisDetails>)}
            </>
          ) : (
            <Button
              data-analytics-id={`intelligence-${title}-show-more`}
              data-test-id="showMore"
              type="link"
              style={{ padding: 0 }}
              onClick={() => setShowMore(true)}
            >
              {t('caseIntelligence.showMore', {
                length: items.length,
              })}
            </Button>
          ))}
      </Card>
    </Col>
  )
}

export default memo(SummaryPanel)
