import { formatCurrencyNumber } from '@signifyd/utils'
import { i18nInstance } from '@signifyd/components'
import { TicketDetailsWithCurrency } from 'pages/CaseReviewPage/containers/OrderDetails/components/EventDetails/types'

export const getTicketDetails = (value: TicketDetailsWithCurrency): string => {
  const details: Array<string> = []

  if (value.section) {
    details.push(
      `${i18nInstance.t('eventTicketing.eventTicketDetails.section')} ${value.section}`
    )
  }

  if (value.row) {
    details.push(
      `${i18nInstance.t('eventTicketing.eventTicketDetails.row')} ${value.row}`
    )
  }

  if (value.seat) {
    details.push(
      `${i18nInstance.t('eventTicketing.eventTicketDetails.seat')} ${value.seat}`
    )
  }

  return `${details.join(' | ')} (${value.currency} ${formatCurrencyNumber(value.ticketPrice)})`
}
