import { useState, useEffect, ReactElement, useMemo, FC } from 'react'
import { useTranslation } from 'react-i18next'
import Icon from '@ant-design/icons'
import { Button, Drawer, Flex, Popconfirm, Typography } from 'antd'
import {
  TableColumnIcon,
  Space,
  CloseOutlined,
  TextThirdGen,
  CircleExclamation,
} from '@signifyd/components'
import { colorSlate, colorGold } from '@signifyd/colors'
import { isEqual, partition } from 'lodash'
import ResponsiveIconButton from 'core/components/ResponsiveIconButton'
import { useStoreActions, useStoreState } from 'store'
import { defaultSortState } from 'store/search/search.store'
import { spacingMD, spacingXS } from '@signifyd/ant'
import { ColumnConfig, getDrawerColumns } from '../columns'
import ThirdGenTableColumnListItem from './TableColumnListItem/ThirdGenTableColumnListItem'
import ThirdGenHiddenColumnsEmpty from './ThirdGenHiddenColumnsEmpty'
import ThirdGenTableColumnSelectFooter from './ThirdGenTableColumnSelectFooter'
import styles from './ThirdGenTableColumnSelectDrawer.less'

interface Props {
  hasAirlineOrders?: boolean
  hasEventTicketingOrders?: boolean
}

const { Title } = Typography

const ThirdGenTableColumnSelectDrawer: FC<Props> = ({
  hasAirlineOrders,
  hasEventTicketingOrders,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'pages.results.columnSelect',
  })

  const { sort } = useStoreState((state) => state.search)
  const { setSortOrder } = useStoreActions((actions) => actions.search)

  const { hiddenColumns } = useStoreState((state) => state.user)
  const { updateUserUIState } = useStoreActions((actions) => actions.user)

  const [drawerVisible, setDrawerVisible] = useState(false)
  const [columns, setColumns] = useState<Array<ColumnConfig>>(
    getDrawerColumns(
      !!hasAirlineOrders,
      hiddenColumns,
      !!hasEventTicketingOrders
    )
  )

  useEffect(() => {
    setColumns(
      getDrawerColumns(
        !!hasAirlineOrders,
        hiddenColumns,
        !!hasEventTicketingOrders
      )
    )
  }, [hiddenColumns, hasAirlineOrders, hasEventTicketingOrders])

  const hasChanges = useMemo(
    () =>
      isEqual(
        getDrawerColumns(
          !!hasAirlineOrders,
          hiddenColumns,
          !!hasEventTicketingOrders
        ),
        columns
      ),
    [hasAirlineOrders, hiddenColumns, hasEventTicketingOrders, columns]
  )

  const displayColumnNames = (
    displayColumns: Array<ColumnConfig>
  ): Array<ReactElement> =>
    displayColumns.map((column) => (
      <ThirdGenTableColumnListItem
        title={column.key}
        isLocked={column.isLocked}
        isHidden={column.isHidden}
        onToggleHidden={() => {
          const newColumns = [...columns]

          newColumns[columns.indexOf(column)].isHidden = !column.isHidden
          setColumns(newColumns)
        }}
        key={column.key}
      />
    ))

  const applyChanges = (): void => {
    const newHiddenColumns = columns
      .filter((column) => column.isHidden)
      .map((column) => column.key)

    updateUserUIState({ searchHiddenColumns: newHiddenColumns })

    // if hiding currently sorted column then reset sort order
    if (newHiddenColumns.includes(sort.by)) {
      setSortOrder(defaultSortState)
    }

    setDrawerVisible(false)
  }

  const handleConditionallyClear = (): void => {
    if (hasChanges) {
      setDrawerVisible(false)
    }
  }

  const clearChanges = (): void => {
    setColumns(
      getDrawerColumns(
        !!hasAirlineOrders,
        hiddenColumns,
        !!hasEventTicketingOrders
      )
    )
    setDrawerVisible(false)
  }

  const [excludes, includes] = partition(columns, (column) => column.isHidden)

  return (
    <>
      <ResponsiveIconButton
        data-test-id="openColumnsIcon"
        data-analytics-id="open-column-config"
        text={
          !excludes.length
            ? t('drawerTitle')
            : t('drawerTitleHidden', {
                count: excludes.length,
              })
        }
        onClick={() => setDrawerVisible(true)}
        Icon={<Icon component={TableColumnIcon} />}
      />
      <Drawer
        title={
          <Flex gap={spacingMD} align="center">
            <Popconfirm
              overlayClassName="sig-popconfirm"
              title={null}
              icon={null}
              description={
                <Flex gap={spacingXS}>
                  <CircleExclamation
                    className={styles.popconfirmIcon}
                    fill={colorGold}
                  />
                  <Flex vertical gap={spacingXS}>
                    <Title level={3}>{t('close.confirmTitle')}</Title>
                    <TextThirdGen>{t('close.confirmText')}</TextThirdGen>
                  </Flex>
                </Flex>
              }
              onConfirm={clearChanges}
              disabled={hasChanges}
              placement="topRight"
              okText={t('close.confirmClose')}
              cancelText={t('close.no')}
            >
              <Button
                onClick={handleConditionallyClear}
                icon={<CloseOutlined fill={colorSlate} />}
                type="text"
                className={styles.closeIcon}
              />
            </Popconfirm>
            <Title level={2}>{t('drawerTitle')}</Title>
          </Flex>
        }
        width={520}
        open={drawerVisible}
        closable={false}
        footer={
          <ThirdGenTableColumnSelectFooter
            columns={columns}
            onSetColumns={setColumns}
            clearChanges={clearChanges}
            applyChanges={applyChanges}
            hasChanges={hasChanges}
            handleConditionallyClear={handleConditionallyClear}
            hasAirlineOrders={hasAirlineOrders}
          />
        }
      >
        {/* Visible Columns */}
        <Title level={3}>{t('visibleColumns')}</Title>

        <Space size="lg" />

        <Flex vertical justify="space-between" gap={8}>
          {displayColumnNames(includes)}
        </Flex>

        {/* Hidden Columns */}
        <Space size="lg" />
        <Title level={3}>{t('hiddenColumns')}</Title>
        <Space size="lg" />

        {!excludes.length ? (
          <ThirdGenHiddenColumnsEmpty />
        ) : (
          <Flex vertical justify="space-between" gap={8}>
            {displayColumnNames(excludes)}
          </Flex>
        )}
      </Drawer>
    </>
  )
}

export default ThirdGenTableColumnSelectDrawer
