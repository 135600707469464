import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { CaseNote, getResubmitStatus, RESUBMIT_STATUS } from '@signifyd/http'
import { useTranslation } from 'react-i18next'
import { message } from 'antd'
import {
  hideAction,
  disableAction,
  enableAction,
} from 'store/caseActions/caseActions.utils'
import { ActionState } from './caseActions/utils'

interface ResubmitStatusPayload {
  investigationId: number
  notes?: Array<CaseNote>
}

export const RESUBMIT_QUERY_KEY = 'resubmitStatus'

export const useResubmitStatus = ({
  investigationId,
}: ResubmitStatusPayload): UseQueryResult<ActionState, AxiosError> => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'store.caseActions.resubmitGuarantee',
  })

  return useQuery<ActionState, AxiosError>(
    [RESUBMIT_QUERY_KEY, investigationId],
    async () => {
      try {
        const { data } = await getResubmitStatus(investigationId)
        const { status, reason } = data

        if (status === RESUBMIT_STATUS.HIDDEN) {
          return hideAction()
        }

        if (status === RESUBMIT_STATUS.DISABLED) {
          const translatedReason = t('reason', {
            context: reason,
          })

          return disableAction(translatedReason)
        }

        return enableAction()
      } catch (error) {
        message.error(t('apiFailure'))
        throw error
      }
    },
    { onError: hideAction }
  )
}
