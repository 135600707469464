import { EkataObject } from '@signifyd/http'
import { DescriptionsItemType } from 'antd/es/descriptions'
import {
  getBooleanTranslation,
  getPhoneIntelligenceLabel,
} from './phoneTranslations'

export const getPhoneIntelligenceItems = (
  ekata: EkataObject
): Array<DescriptionsItemType> => {
  return [
    ...ekata.alternatePhones.map((phoneDetails) => ({
      label: getPhoneIntelligenceLabel('alternatePhone'),
      children: phoneDetails,
    })),
    ekata.carrier && {
      label: getPhoneIntelligenceLabel('carrier'),
      children: ekata.carrier,
    },
    ekata.lineType && {
      label: getPhoneIntelligenceLabel('phoneType'),
      children: ekata.lineType,
    },
    ekata.prepaid && {
      label: getPhoneIntelligenceLabel('prepaid'),
      children: getBooleanTranslation(ekata.prepaid),
    },
    ekata.commercial && {
      label: getPhoneIntelligenceLabel('commercial'),
      children: getBooleanTranslation(ekata.commercial),
    },
    ...ekata.belongsTo.flatMap((entity) => [
      {
        label: entity.type,
      },
      {
        label: getPhoneIntelligenceLabel('name'),
        children: entity.name,
      },
      entity.age_range && {
        label: getPhoneIntelligenceLabel('age_range'),
        children: entity.age_range,
      },
      entity.gender && {
        label: getPhoneIntelligenceLabel('gender'),
        children: entity.gender,
      },
      entity.link_to_phone_start_date && {
        label: getPhoneIntelligenceLabel('link_to_phone_start_date'),
        children: entity.link_to_phone_start_date,
      },
    ]),
    ekata.associatedPeople.length > 0 && {
      label: (
        <div data-test-id="phoneIntelligence-associatedPeople">
          {getPhoneIntelligenceLabel('associatedPeople')}
        </div>
      ),
    },
    ...ekata.associatedPeople.map((person) => ({
      label: person.name,
      children: (
        <>
          {getPhoneIntelligenceLabel('relation')}:{' '}
          {person.relation || getPhoneIntelligenceLabel('unknown')}
        </>
      ),
    })),
    ekata.currentAddresses.length > 0 && {
      label: getPhoneIntelligenceLabel('addresses'),
    },
    ...ekata.currentAddresses.map((address) => {
      const [addressLine1, addressLine2, ...addressDetails] = [
        address.street_line_1,
        address.city,
        address.state_code,
        address.country_code,
        address.postal_code,
      ].filter((detail) => detail)

      return {
        label: getPhoneIntelligenceLabel('address'),
        children: (
          <>
            {`${addressLine1}, `}
            <br />
            {`${addressLine2}, ${addressDetails.join(' ')}`}
          </>
        ),
      }
    }),
  ] as Array<DescriptionsItemType>
}
