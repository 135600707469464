import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import { message } from 'antd'
import { AxiosError } from 'axios'
import {
  replaceGuaranteeDisposition,
  createCaseNote,
  attachFileToInvestigationNote,
  INV_GUARANTEE_DISPOSITION,
} from '@signifyd/http'
import { useTranslation } from 'react-i18next'
import { UploadFile } from 'antd/lib'
import { useStoreActions } from 'store'
import { RESUBMIT_QUERY_KEY } from './useResubmitStatus'

interface ResubmitGuaranteePayload {
  payload: string
  files: Array<UploadFile<unknown>>
}

export const useResubmitGuarantee = (
  investigationId: number
): UseMutationResult<void, AxiosError, ResubmitGuaranteePayload> => {
  const { t } = useTranslation()
  const { refreshCurrentCase } = useStoreActions(
    (actions) => actions.currentCase
  )
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ payload, files }: ResubmitGuaranteePayload) => {
      await replaceGuaranteeDisposition(
        investigationId,
        INV_GUARANTEE_DISPOSITION.PENDING
      )

      const { data: createdNote } = await createCaseNote(
        investigationId,
        payload
      )

      const attachFilePromises = files.map((file) =>
        attachFileToInvestigationNote({
          file,
          fileName: file.name,
          investigationId,
          noteId: createdNote.noteId,
        })
      )

      await Promise.all(attachFilePromises)
    },
    onSuccess: () => {
      refreshCurrentCase()
      queryClient.invalidateQueries([RESUBMIT_QUERY_KEY])
      message.success(t('store.caseActions.resubmitGuarantee.apiSuccess'))
    },
    onError: () => {
      message.error(t('store.caseActions.resubmitGuarantee.apiFailure'))
    },
  })
}
