import { FC } from 'react'
import { Descriptions } from 'antd'
import { formatCurrencyNumber } from '@signifyd/utils'
import { InvestigationInfo } from '@signifyd/http'
import { useTranslation } from 'react-i18next'
import { displayShippingMethod } from 'core/utils/textTransforms'
import NoInformation from 'core/components/NoInformation'
import { DescriptionsItemType } from 'antd/es/descriptions'
import { defaultDescriptionProps } from 'core/constants'
import ShippingSummaryLabel from './ShippingSummaryLabel'

interface Props {
  caseDetails: InvestigationInfo
}

export const ShippingSummaryThirdGen: FC<Props> = ({ caseDetails }) => {
  const { t } = useTranslation()
  const sectionName = t('caseSummary.shipping.title')

  if (caseDetails?.shipments.length === 0) {
    return (
      <Descriptions
        title={sectionName}
        {...defaultDescriptionProps}
        items={[
          {
            label: '',
            labelStyle: { display: 'none' },
            children: <NoInformation />,
          },
        ]}
      />
    )
  }

  const items: Array<DescriptionsItemType> = [
    {
      label: t('caseSummary.shipping.title'),
      children: t('caseSummary.shipping.price'),
    },
  ]

  if (caseDetails?.shipments) {
    const shipmentItems: Array<DescriptionsItemType> =
      caseDetails.shipments.map((shipment) => {
        return {
          label: (
            <span data-test-id="shippingDetails">
              {displayShippingMethod(shipment)}
            </span>
          ),
          children: `${caseDetails.currency || 'USD'} ${formatCurrencyNumber(
            shipment.shippingPrice || 0
          )}`,
        }
      })

    items.push(...shipmentItems)
  }

  return (
    <Descriptions
      title={
        <ShippingSummaryLabel
          sectionName={sectionName}
          shipments={caseDetails?.shipments}
        />
      }
      {...defaultDescriptionProps}
      items={items}
    />
  )
}
export default ShippingSummaryThirdGen
