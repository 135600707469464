import { FC, ReactNode } from 'react'
import { Popover, Button } from 'antd'
import { SEARCH_FIELD } from '@signifyd/http'
import { Text } from '@signifyd/components'
import styles from './MultiItemPopover.less'

interface ItemPopoverProps {
  items: Array<string | null | undefined>
  title: string
  isLinked?: boolean
  searchPrefix?: SEARCH_FIELD
}

const MultiItemPopover: FC<ItemPopoverProps> = ({ items, title }) => {
  const [firstItemValue] = items

  if (!firstItemValue) {
    return null
  }

  const uniqueItemValues = [...(new Set(items.filter(Boolean)) as Set<string>)]

  const popoverContent = uniqueItemValues.map((value): ReactNode => {
    return (
      <Text className={styles.popoverContent} key={value}>
        {value}
      </Text>
    )
  })

  return (
    <>
      {firstItemValue}

      {uniqueItemValues.length > 1 && (
        <span data-test-id="multipleItemPopover">
          <Popover
            placement="topLeft"
            title={title}
            content={popoverContent}
            trigger="hover"
          >
            <Button className={styles.itemPopoverBtn} shape="circle">
              +{uniqueItemValues.length - 1}
            </Button>
          </Popover>
        </span>
      )}
    </>
  )
}

export default MultiItemPopover
