import { FC, useMemo, useState } from 'react'
import {
  ArrowLeftLong,
  OutlinedLeft,
  OutlinedRight,
} from '@signifyd/components'
import { SearchOutlined } from '@ant-design/icons'
import { Button, Divider, Flex, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useStoreActions, useStoreState } from 'store'
import SearchAndFilter from 'core/components/SearchAndFilter'
import { useSearchFilters } from 'core/hooks'
import { isTransportAirline } from 'core/utils/isTransportAirline/isTransportAirline'
import { colorCerulean } from '@signifyd/colors'
import styles from './CaseHeaderNavigation.less'

const resetSearchFilters = true

export const CaseHeaderNavigationThirdGen: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { filters, updateFilters, searchTerm, updateSearchTerm } =
    useSearchFilters({ resetFiltersOnMount: resetSearchFilters })

  // Store State
  const { searchCasesWithSavedFilters } = useStoreActions(
    (actions) => actions.search
  )
  const searchResults = useStoreState((state) => state.search.results)
  const caseDetails = useStoreState((state) => state.currentCase.details)
  const [showSearchComponent, setShowSearchComponent] = useState(false)

  const isAirlineOrder = caseDetails && isTransportAirline(caseDetails.products)

  // Component State
  const hasResults = !!searchResults.length
  const currentIndex = useMemo(() => {
    return searchResults.findIndex(
      ({ investigationId }) => investigationId === caseDetails?.investigationId
    )
  }, [caseDetails, searchResults])
  const isFirstCase = currentIndex === 0
  const isLastCase = currentIndex === searchResults.length - 1

  const placeholderText = isAirlineOrder ? 'airlinePlaceholder' : 'placeholder'

  // Handlers
  const handleNavigateToCase = (index: number): void => {
    const { investigationId } = searchResults[index]

    navigate(`/orders/${investigationId}`)
  }

  const handleBack = (): void => {
    if (hasResults) {
      searchCasesWithSavedFilters({ navigate })
    } else {
      navigate('/orders/search')
    }
  }

  const handleClose = (): void => {
    setShowSearchComponent(false)
  }

  return (
    <>
      {!showSearchComponent && (
        <Flex className={styles.navigation}>
          <Button
            type="link"
            onClick={handleBack}
            data-test-id="back-to-search"
            className={styles.backToSearchButton}
            icon={<ArrowLeftLong fill={colorCerulean} />}
          >
            {t(
              `pages.caseReview.header.${
                hasResults ? 'searchResults' : 'search'
              }`
            )}
          </Button>
          <Divider
            className={styles.divider}
            type="vertical"
            orientation="center"
          />
          <div className={styles.searchNavigation}>
            <Button
              onClick={() => setShowSearchComponent(!showSearchComponent)}
              data-test-id="toggle-search-component"
              className={styles.searchFilterLink}
              icon={<SearchOutlined />}
              color="default"
              variant="link"
            >
              {t(`search.searchBar.${placeholderText}`)}
            </Button>
          </div>
          <>
            {hasResults && currentIndex >= 0 && (
              <Flex
                align="center"
                data-test-id="pagination"
                className={styles.pagination}
              >
                <Typography.Title level={4} className={styles.results}>
                  {t('pages.caseReview.header.count', {
                    current: currentIndex + 1,
                    total: searchResults.length,
                  })}
                </Typography.Title>
                <button
                  data-test-id="paginationUpArrow"
                  className={styles.paginationButton}
                  onClick={() =>
                    !isFirstCase && handleNavigateToCase(currentIndex - 1)
                  }
                >
                  <OutlinedLeft fill={colorCerulean} />
                </button>
                <button
                  data-test-id="paginationDownArrow"
                  className={styles.paginationButton}
                  onClick={() =>
                    !isLastCase && handleNavigateToCase(currentIndex + 1)
                  }
                >
                  <OutlinedRight fill={colorCerulean} />
                </button>
              </Flex>
            )}
          </>
        </Flex>
      )}
      {showSearchComponent && (
        <div className={styles.searchFilterNavigation}>
          <SearchAndFilter
            defaultOpen
            handleClose={handleClose}
            filters={filters}
            updateFilters={updateFilters}
            searchTerm={searchTerm}
            updateSearchTerm={updateSearchTerm}
            hasAirlineOrders={!!isAirlineOrder}
          />
        </div>
      )}
    </>
  )
}

export default CaseHeaderNavigationThirdGen
