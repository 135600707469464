import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Table } from 'antd'
import { InvestigationInfo } from '@signifyd/http'
import { T4 } from '@signifyd/components'
import { getEventDetailsColumnConfig } from 'pages/CaseReviewPage/containers/OrderDetails/components/EventDetails/EventDetailsTable.config'
import styles from './EventDetails.less'

interface Props {
  caseDetails: InvestigationInfo
}

export const EventDetails: FC<Props> = ({ caseDetails }) => {
  const { t } = useTranslation()

  const { products, currency } = caseDetails

  const columnConfig = useMemo(() => {
    return getEventDetailsColumnConfig(currency)
  }, [currency])

  return (
    <Card
      title={
        <T4 className={styles.title}>
          {t('eventTicketing.tableHeader.eventDetails')}
        </T4>
      }
      data-test-id="eventDetails"
      data-analytics-id="event-details"
      size="small"
    >
      <Table
        dataSource={products}
        rowKey="itemId"
        columns={columnConfig}
        scroll={{ x: true }}
        pagination={false}
      />
    </Card>
  )
}

export default EventDetails
