import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Descriptions } from 'antd'
import { InvestigationInfo, PREDICTION_LIST_TYPE } from '@signifyd/http'
import { CaseEntriesResolvedState } from 'store/currentCase/types/case.entries.types'
import CopyWrapper from 'core/components/CopyWrapper'
import NoInformation from 'core/components/NoInformation'
import SeederLabel from 'pages/CaseReviewPage/components/SeederLabel'
import OrderAttributeLists from 'core/components/OrderAttributeLists'
import { DescriptionsItemType } from 'antd/es/descriptions'
import { defaultDescriptionProps } from 'core/constants'
import DeepLinkDropdown from '../components/DeepLinkDropdown'
import {
  MENU_SECTION,
  MENU_SECTION_TYPE,
} from '../components/DeepLinkDropdown/DeepLinkDropdown.types'
import { getPhoneIntelligenceItems } from './getPhoneIntelligenceItems'

export interface PhoneProps {
  caseDetails: InvestigationInfo
  caseEntries: CaseEntriesResolvedState
}

const Phone: FC<PhoneProps> = ({ caseEntries, caseDetails }) => {
  const { t } = useTranslation()

  const { confirmationPhone, accountHolderPhone } = caseEntries
  const { recipients, userAccountPhone } = caseDetails

  const recipientConfirmationPhone = recipients?.[0]?.confirmationPhone

  const noInfo = !confirmationPhone && !userAccountPhone
  const sectionName = t('pages.caseReview.details.phone')

  const phonesAreIdentical = recipientConfirmationPhone === userAccountPhone

  const confirmationPhoneIntelligenceItems =
    !phonesAreIdentical && confirmationPhone?.details?.wppReversePhoneJson
      ? getPhoneIntelligenceItems(confirmationPhone.details.wppReversePhoneJson)
      : []

  const userAccountPhoneIntelligenceItems = accountHolderPhone?.details
    ?.wppReversePhoneJson
    ? getPhoneIntelligenceItems(
        accountHolderPhone?.details?.wppReversePhoneJson
      )
    : []

  const items = [
    recipientConfirmationPhone && {
      label: (
        <SeederLabel label={t('pages.caseReview.details.confirmationPhone')} />
      ),
      children: (
        <>
          <CopyWrapper text={recipientConfirmationPhone}>
            <DeepLinkDropdown
              section={MENU_SECTION.phone}
              type={MENU_SECTION_TYPE.confirmationPhone}
            >
              {recipientConfirmationPhone}
            </DeepLinkDropdown>
          </CopyWrapper>
          <OrderAttributeLists
            type={PREDICTION_LIST_TYPE.PHONE_NUMBER}
            value={recipientConfirmationPhone}
            analyticsId="confirmation-phone"
          />
        </>
      ),
    },
    ...confirmationPhoneIntelligenceItems,
    userAccountPhone && {
      label: (
        <SeederLabel label={t('pages.caseReview.details.accountHolderPhone')} />
      ),
      children: (
        <>
          <CopyWrapper text={userAccountPhone}>
            <DeepLinkDropdown
              section={MENU_SECTION.phone}
              type={MENU_SECTION_TYPE.accountPhone}
            >
              {userAccountPhone}
            </DeepLinkDropdown>
          </CopyWrapper>
          <OrderAttributeLists
            type={PREDICTION_LIST_TYPE.PHONE_NUMBER}
            value={userAccountPhone}
            analyticsId="account-phone"
          />
        </>
      ),
    },
    ...userAccountPhoneIntelligenceItems,
    noInfo && {
      label: '',
      labelStyle: { display: 'none' },
      children: <NoInformation />,
    },
  ].filter((item) => !!item) as Array<DescriptionsItemType>

  return (
    <Descriptions
      title={sectionName}
      items={items}
      {...defaultDescriptionProps}
    />
  )
}

export default Phone
