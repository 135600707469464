import { FC } from 'react'
import { Descriptions, Tooltip } from 'antd'
import { formatCurrencyNumber } from '@signifyd/utils'
import { useTranslation } from 'react-i18next'
import {
  CaseOrder,
  InvestigationInfo,
  PREDICTION_LIST_TYPE,
} from '@signifyd/http'
import FormattedDate from 'core/components/FormattedDate'
import NoInformation from 'core/components/NoInformation'
import OrderAttributeLists from 'core/components/OrderAttributeLists'
import { defaultDescriptionProps } from 'core/constants'

export interface AccountSummaryProps {
  orderDetails: CaseOrder
  caseDetails: InvestigationInfo
}

export const AccountSummaryThirdGen: FC<AccountSummaryProps> = ({
  orderDetails,
  caseDetails,
}) => {
  const { t } = useTranslation()

  const noInfo =
    !orderDetails?.buyerAccount?.membershipNumbers?.length &&
    !caseDetails?.userAccountNumber &&
    !caseDetails?.userAccountAggregateOrderCount &&
    !caseDetails?.userAccountAggregateOrderDollars &&
    !caseDetails?.userAccountCreatedDate &&
    !caseDetails?.userAccountLastUpdateDate &&
    !caseDetails?.userAccountLastOrderExternalId
  const sectionName = t('caseSummary.account.title')

  if (noInfo) {
    return (
      <>
        <Descriptions
          title={sectionName}
          {...defaultDescriptionProps}
          items={[
            {
              label: '',
              labelStyle: { display: 'none' },
              children: <NoInformation />,
            },
          ]}
        />
      </>
    )
  }

  const {
    userAccountNumber,
    currency,
    userAccountLastUpdateDate,
    userAccountAggregateOrderCount,
    userAccountAggregateOrderDollars,
    userAccountCreatedDate,
    userAccountLastOrderExternalId,
  } = caseDetails
  const {
    buyerAccount: { membershipNumbers },
  } = orderDetails

  return (
    <>
      <Descriptions
        title={sectionName}
        {...defaultDescriptionProps}
        items={[
          userAccountNumber && {
            label: t('caseSummary.account.number'),
            children: (
              <>
                <div>{userAccountNumber}</div>
                {/** TODO: Style these */}
                <OrderAttributeLists
                  type={PREDICTION_LIST_TYPE.ACCOUNT_NUMBER}
                  value={userAccountNumber}
                  analyticsId="account-number"
                />
              </>
            ),
          },
          userAccountAggregateOrderCount && {
            label: t('caseSummary.account.orderCount'),
            children: userAccountAggregateOrderCount,
          },
          userAccountAggregateOrderDollars && {
            label: t('caseSummary.account.orderAmount'),
            children: (
              <>
                {`${currency || 'USD'} ${formatCurrencyNumber(
                  userAccountAggregateOrderDollars
                )}`}
              </>
            ),
          },
          userAccountCreatedDate && {
            label: t('caseSummary.account.createdDate'),
            children: <FormattedDate date={userAccountCreatedDate} />,
          },
          userAccountLastUpdateDate && {
            label: t('caseSummary.account.lastUpdated'),
            children: <FormattedDate date={userAccountLastUpdateDate} />,
          },
          userAccountLastOrderExternalId && {
            label: t('caseSummary.account.lastOrder'),
            children: userAccountLastOrderExternalId,
          },
          !!membershipNumbers?.length && {
            label: t('caseSummary.account.membership'),

            children: (
              <>
                <Tooltip title={membershipNumbers.join(', ')}>
                  <div>{membershipNumbers.join(', ')}</div>
                </Tooltip>
                {/** TODO: Style these */}
                <OrderAttributeLists
                  type={PREDICTION_LIST_TYPE.MEMBERSHIP_ID}
                  value={membershipNumbers.join(', ')}
                  analyticsId="membership-number"
                />
              </>
            ),
          },
        ].filter((x) => !!x)}
      />
    </>
  )
}
export default AccountSummaryThirdGen
