import { FC, useEffect } from 'react'
import { notification } from 'antd'
import moment from 'moment-timezone'
import {
  useAppCues,
  TrialBanner,
  i18nInstance,
  initLocalization,
  useCurrentAppLocale,
  AppConfigProvider,
  QAInfoPopover,
  ProdWarningModal,
  useIsThirdGen,
} from '@signifyd/components'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { BrowserRouter } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import { legacyTheme, thirdGenTheme } from '@signifyd/ant'
import OnboardingScreen from 'core/components/OnboardingScreen'
import locales from 'locales'
import { useStoreActions, useStoreState } from 'store'
import useHomepageRedirect from 'core/hooks/useHomepageRedirect'
import AppLayout from 'core/components/AppLayout/AppLayout'
import styles from './App.less'

initLocalization(locales)

export const App: FC = () => {
  // We awaited user loading in index, so we can safely non null assert here
  const currentUser = useStoreState((state) => state.user.currentUser)!
  const userConfigs = useStoreState((state) => state.user.userConfigs)!
  const setIsThirdGen = useStoreActions(
    (actions) => actions.search.setIsThirdGen
  )

  const { selectedLocale } = useCurrentAppLocale(currentUser)

  const isThirdGen = useIsThirdGen()

  useAppCues({
    user: currentUser,
    appName: 'customer-case-console',
    allowedBuildEnvs: window.isRegressionTest ? [] : ['staging', 'production'],
    buildEnv: process.env.BUILD_ENV,
  })

  // Updated plugin notification message
  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const pluginUpdated = urlParams.get('plugin_updated')

    if (pluginUpdated?.toLowerCase() === 'shopify') {
      notification.success({
        message: i18nInstance.t('pluginUpdatedNotification.message'),
        description: i18nInstance.t('pluginUpdatedNotification.description'),
        duration: 0,
      })
    }
  }, [])

  const userTimeZone = currentUser?.uiState?.timeZone?.name

  useEffect(() => {
    if (userTimeZone) {
      moment.tz.setDefault(userTimeZone)
    }
  }, [userTimeZone])

  useHomepageRedirect()

  const theme = isThirdGen ? thirdGenTheme : legacyTheme

  // Tooltips, popovers, and similar elements in ant are nested under a separate top level section under the body, this targets those too
  useEffect(() => {
    const themeClassName = isThirdGen ? 'thirdGen' : 'secondGen'
    document.body.classList.add(themeClassName)

    setIsThirdGen(isThirdGen)

    return () => {
      document.body.classList.remove('thirdGen', 'secondGen')
    }
  }, [isThirdGen, setIsThirdGen])

  return (
    <div className={styles.appWrapper}>
      <BrowserRouter>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <AppConfigProvider
            theme={theme}
            localization={{ appName: 'orders' }}
            user={currentUser}
            userConfigs={userConfigs}
          >
            <OnboardingScreen
              username={currentUser.username}
              key={selectedLocale}
            >
              <AppLayout />
              <QAInfoPopover />
              <TrialBanner user={currentUser} />
            </OnboardingScreen>
          </AppConfigProvider>
          <ProdWarningModal />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryParamProvider>
      </BrowserRouter>
    </div>
  )
}

export default App
