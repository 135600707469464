import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Descriptions } from 'antd'
import { CaseEntriesResolvedState } from 'store/currentCase/types/case.entries.types'
import CopyWrapper from 'core/components/CopyWrapper'
import NoInformation from 'core/components/NoInformation'
import { DescriptionsItemType } from 'antd/lib/descriptions'
import { defaultDescriptionProps } from 'core/constants'

interface Props {
  caseEntries: CaseEntriesResolvedState
}

const Organization: FC<Props> = ({ caseEntries }) => {
  const { t } = useTranslation()
  const { asnCarrier, ipRegistree, creditCardIssuer } = caseEntries

  const noInfo = !asnCarrier && !ipRegistree && !creditCardIssuer

  const items = [
    asnCarrier?.entityName && {
      label: t('pages.caseReview.details.ASNCarrier'),
      children: (
        <CopyWrapper text={asnCarrier.entityName}>
          {asnCarrier.entityName}
        </CopyWrapper>
      ),
    },
    ipRegistree?.entityName && {
      label: t('pages.caseReview.details.ipRegistree'),
      children: (
        <CopyWrapper text={ipRegistree.entityName}>
          {ipRegistree.entityName}
        </CopyWrapper>
      ),
    },
    creditCardIssuer?.entityName && {
      label: t('pages.caseReview.details.creditCardIssuer'),
      children: (
        <CopyWrapper text={creditCardIssuer.entityName}>
          {creditCardIssuer.entityName}
        </CopyWrapper>
      ),
    },
    noInfo && {
      label: '',
      labelStyle: { display: 'none' },
      children: <NoInformation />,
    },
  ].filter((item) => !!item) as Array<DescriptionsItemType>

  return (
    <Descriptions
      title={t('pages.caseReview.details.organization')}
      items={items}
      {...defaultDescriptionProps}
    />
  )
}

export default Organization
