import { FC } from 'react'
import {
  ExternalLink,
  GuaranteeTagThirdGen,
  RecommendationTagThirdGen,
  ReturnTagThirdGen,
  CheckoutTagThirdGen,
} from '@signifyd/components'
import { useTranslation } from 'react-i18next'
import {
  InvestigationInfo,
  INV_CASE_TYPE,
  DECISION_MECHANISM,
  USER_ROLE,
} from '@signifyd/http'
import { InfoCircleFilled } from '@ant-design/icons'
import { Flex, Tag, Tooltip, Typography } from 'antd'
import { spacingMD, spacingSM } from '@signifyd/ant'
import { PLATFORM_URL_PREFIXES_BY_TEAM_ID } from 'core/constants'
import FormattedAmount from 'core/components/FormattedAmount'
import { showReturnsTag } from 'core/utils/filterReturns'
import { isTransportAirline } from 'core/utils/isTransportAirline/isTransportAirline'
import { useStoreState } from 'store'
import { colorGold } from '@signifyd/colors'
import CaseHeaderDetails from './CaseHeaderDetails'
import styles from './CaseHeaderThirdGen.less'
import AirlineNameRecord from './AirlineNameRecord/AirlineNameRecord'
import CaseHeaderActionsBar from './CaseHeaderActionsBar/CaseHeaderActionsBar'

interface Props {
  caseDetails: InvestigationInfo | null
}

export const CaseHeaderThirdGen: FC<Props> = ({ caseDetails }) => {
  const { t } = useTranslation()

  const currentUser = useStoreState((state) => state.user.currentUser)

  if (!caseDetails) {
    return null
  }

  const displayReturnsTag = showReturnsTag(caseDetails.returns)
  const isAirlineOrder = isTransportAirline(caseDetails.products)

  const isPolicyOnlyDecisionMechanism =
    caseDetails.decisionMechanism === DECISION_MECHANISM.POLICY_ONLY_ACCEPT

  return (
    <>
      <Flex
        justify="space-between"
        wrap
        className={styles.caseHeader}
        gap={spacingMD}
      >
        <Flex wrap gap={spacingMD}>
          <Typography.Title
            level={1}
            className={styles.orderTitleText}
            data-test-id="orderTitleText"
            data-analytics-id="order-title-text"
          >
            {isAirlineOrder && (
              <>
                <AirlineNameRecord products={caseDetails.products} />
                <div className={styles.divider} />
              </>
            )}
            <span className={styles.orderItem}>
              #
              {PLATFORM_URL_PREFIXES_BY_TEAM_ID[caseDetails.teamId] ? (
                <ExternalLink
                  target="_blank"
                  url={`${PLATFORM_URL_PREFIXES_BY_TEAM_ID[caseDetails.teamId]}/${
                    caseDetails.platformOrderId || caseDetails.orderExternalId
                  }`}
                >
                  {caseDetails.platformOrderId || caseDetails.orderExternalId}
                </ExternalLink>
              ) : (
                caseDetails.orderExternalId
              )}
            </span>
            <div className={styles.divider} />
            <span className={styles.orderItem}>{caseDetails.headline}</span>
            <div className={styles.divider} />
            <span className={styles.orderText}>
              <FormattedAmount caseDetails={caseDetails} />
            </span>
          </Typography.Title>
          <Flex
            className={styles.orderTags}
            gap={spacingMD}
            wrap
            align="center"
            justify="center"
          >
            {!isPolicyOnlyDecisionMechanism &&
              caseDetails.recommendedDecisionDisposition && (
                <div data-test-id="recommendationTag">
                  <RecommendationTagThirdGen
                    tooltipPlacement="bottom"
                    recommendedDecisionDisposition={
                      caseDetails.recommendedDecisionDisposition
                    }
                  />
                </div>
              )}
            {caseDetails.guaranteeDisposition && (
              <div data-test-id="guaranteeTag">
                <GuaranteeTagThirdGen
                  disposition={caseDetails.guaranteeDisposition}
                />
              </div>
            )}
            <CheckoutTagThirdGen
              isRulesBuilderUser={currentUser?.roles.includes(
                USER_ROLE.RULES_BUILDER
              )}
              investigationInfoPolicyDetails={caseDetails}
              showNoMatch={false}
            />
            {displayReturnsTag && (
              <div data-test-id="returnTag">
                <ReturnTagThirdGen returns={caseDetails.returns} />
              </div>
            )}
            {caseDetails.caseType === INV_CASE_TYPE.TRIAL && (
              <div data-test-id="trialOrder">
                <Tooltip
                  placement="bottom"
                  title={t('pages.caseReview.header.trialOrderTooltip')}
                >
                  <Tag color={colorGold}>
                    <InfoCircleFilled />
                    <span>{t('pages.caseReview.header.trialOrder')}</span>
                  </Tag>
                </Tooltip>
              </div>
            )}
            {caseDetails.isTestInvestigation && (
              <div data-test-id="testOrder">
                <Tooltip
                  placement="bottom"
                  title={t('pages.caseReview.header.testOrderTip')}
                >
                  <Tag color={colorGold}>
                    <InfoCircleFilled />
                    <span>{t('pages.caseReview.header.testOrder')}</span>
                  </Tag>
                </Tooltip>
              </div>
            )}
          </Flex>
        </Flex>
        <Flex gap={spacingSM} wrap align="center">
          <CaseHeaderActionsBar />
        </Flex>
      </Flex>
      <CaseHeaderDetails caseDetails={caseDetails} />
    </>
  )
}

export default CaseHeaderThirdGen
