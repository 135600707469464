import eventRisks from './eventRisk'
import eventTypes from './eventTypes'

export default {
  tableHeader: {
    confirmationNumber: 'Confirmation number',
    eventDetails: 'Event details',
    eventName: 'Event name',
    eventType: 'Event type',
    eventVenue: 'Venue',
    ticketDetails: 'Ticket details',
    totalPrice: 'Total price',
    eventStart: 'Event start',
    eventEnd: 'Event end',
    saleDate: 'Sale date',
    eventRisk: 'Event risk',
  },
  viewBtn: {
    viewMore: 'View more',
    viewLess: 'View less',
  },
  eventTicketDetails: {
    section: 'Section',
    row: 'Row',
    seat: 'Seat',
    ticketPrice: 'Ticket price',
  },
  eventRisks,
  eventTypes,
}
