import { isNil } from 'lodash'
import moment from 'moment-timezone'
import {
  INV_CASE_TYPE,
  INV_GUARANTEE_DISPOSITION,
  InvestigationInfo,
  User,
} from '@signifyd/http'
import { CASE_CAPABILITIES, hasCapability } from 'core/utils/capabilities'
import { isTransportAirline } from 'core/utils/isTransportAirline/isTransportAirline'
import { getPastStartDate } from 'core/utils/date.utils'
import {
  disableAction,
  enableAction,
  hideAction,
} from 'store/caseActions/caseActions.utils'
import { useUserHasReviewerRole } from 'core/hooks/userHasReviewerRole'
import { useTranslation } from 'react-i18next'

const translationPrefix = 'store.caseActions.updateAddress'

export interface ActionState {
  disabled: boolean
  hidden?: boolean
  disabledReason?: string
}

export const useCanUpdateAddress = (
  caseDetails: InvestigationInfo,
  user: User | null
): ActionState => {
  const { t } = useTranslation()

  const hasReviewerRole = useUserHasReviewerRole(caseDetails.teamId)

  if (user?.isAdmin && !isTransportAirline(caseDetails.products)) {
    return enableAction()
  }

  const maxDays = hasCapability(
    caseDetails.teamId,
    caseDetails.customerId,
    CASE_CAPABILITIES.ALLOW_180_DAYS_FOR_ADDRESS_UPDATES
  )
    ? 180
    : 14

  const hasMultipleUpdates = hasCapability(
    caseDetails.teamId,
    caseDetails.customerId,
    CASE_CAPABILITIES.ALLOW_10_ADDRESS_UPDATES
  )

  const maxUpdates = hasMultipleUpdates ? 10 : 1

  const allowedDispositions = hasMultipleUpdates
    ? [
        INV_GUARANTEE_DISPOSITION.PENDING,
        INV_GUARANTEE_DISPOSITION.APPROVED,
        INV_GUARANTEE_DISPOSITION.DECLINED,
        INV_GUARANTEE_DISPOSITION.IN_REVIEW,
      ]
    : [INV_GUARANTEE_DISPOSITION.APPROVED, INV_GUARANTEE_DISPOSITION.DECLINED]

  if (
    // Hide if guarantee disposition is not one of the following
    caseDetails.guaranteeDisposition &&
    !allowedDispositions.includes(
      caseDetails.guaranteeDisposition as INV_GUARANTEE_DISPOSITION
    )
  ) {
    return hideAction()
  }

  if (hasReviewerRole) {
    return disableAction(t(`${translationPrefix}.notAllowed`))
  }

  // Disable if test order
  if (caseDetails.isTestInvestigation) {
    return disableAction(t(`${translationPrefix}.testCase`))
  }

  // Disable is Trial order
  if (caseDetails.caseType === INV_CASE_TYPE.TRIAL) {
    return disableAction(t(`${translationPrefix}.trialCase`))
  }

  // Disable if has an active claim against it
  if (caseDetails.claims.length > 0) {
    return disableAction(t(`${translationPrefix}.hasClaim`))
  }

  // Disable if order is older than 14/180 days
  if (
    moment(caseDetails.normalizedPurchaseCreatedAt).isBefore(
      getPastStartDate({ subtractInterval: 'days', subtractValue: maxDays })
    )
  ) {
    return disableAction(t(`${translationPrefix}.tooOld`, { days: maxDays }))
  }

  // Disable if not yet submitted for guarantee
  if (isNil(caseDetails.guaranteeDisposition)) {
    return enableAction()
  }

  // Disable if decision count > 1, orders can only be re-decisioned once.
  if (
    !!caseDetails.guaranteeDecisionCount &&
    caseDetails.guaranteeDecisionCount > maxUpdates
  ) {
    return disableAction(t(`${translationPrefix}.alreadyUpdated`))
  }

  // Disable if the case doesn't have a team id (necessary for reroute call)
  if (!caseDetails.teamId) {
    return disableAction(t('caseActions.updateAddress.noTeamId'))
  }

  if (isTransportAirline(caseDetails.products)) {
    return hideAction()
  }

  // If we're here, action can be seen and used
  return enableAction()
}
