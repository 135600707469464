import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DollarOutlined } from '@ant-design/icons'
import { CircleDollar, useIsThirdGen } from '@signifyd/components'
import {
  canPerformAction,
  useClaimReimbursement,
} from 'core/hooks/caseActions/useClaimReimbursement/useClaimReimbursement'
import {
  ACTION_CONTEXT,
  ClaimReimbursementRequiredFields,
} from 'core/hooks/caseActions/useClaimReimbursement/types'
import ClaimReimbursementModal from 'pages/CaseReviewPage/containers/ClaimReimbursementModal'
import { useUserHasReviewerRole } from 'core/hooks/userHasReviewerRole'
import ActionButton from '../ActionButton'
import ActionIconButton from '../ActionIconButton/ActionIconButton'

interface Props {
  caseDetails: ClaimReimbursementRequiredFields
  context: ACTION_CONTEXT
  hideIcon?: boolean
  isMoreOption?: boolean
}

export const ClaimReimbursementButton: FC<Props> = ({
  caseDetails,
  context,
  hideIcon,
  isMoreOption,
}) => {
  const { t } = useTranslation()

  const hasReviewerRole = useUserHasReviewerRole(caseDetails.teamId)

  const [modalVisible, setModalVisible] = useState(false)

  const {
    apiErrors,
    isLoading,
    performAction,
    reset: resetApiCall,
  } = useClaimReimbursement(context)

  const actionState = useMemo(
    () => canPerformAction(caseDetails, hasReviewerRole),
    [caseDetails, hasReviewerRole]
  )

  const isThirdGen = useIsThirdGen()

  // If it's third gen but has more options then we don't want to show the action icon
  // but the regular normal claimReimbursement button with no icon
  const isThirdGenActionIconButton = isThirdGen && !isMoreOption

  return (
    <>
      {isThirdGenActionIconButton ? (
        <ActionIconButton
          onClick={() => setModalVisible(true)}
          actionState={actionState}
          isLoading={isLoading}
          Icon={CircleDollar}
          tooltip={t('caseActions.claimReimbursement')}
        />
      ) : (
        <ActionButton
          onClick={() => setModalVisible(true)}
          actionState={actionState}
          isLoading={isLoading}
          icon={hideIcon ? undefined : <DollarOutlined />}
          text={t('caseActions.claimReimbursement')}
        />
      )}

      <ClaimReimbursementModal
        apiErrors={apiErrors}
        caseDetails={caseDetails}
        closeModal={() => {
          setModalVisible(false)
          resetApiCall()
        }}
        isLoading={isLoading}
        performAction={performAction}
        visible={modalVisible}
      />
    </>
  )
}

export default ClaimReimbursementButton
