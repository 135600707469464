import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Flex, Typography } from 'antd'
import { Membership } from '@signifyd/http'
import { ErrorBoundary } from '@signifyd/components'
import { spacingSM } from '@signifyd/ant'
import MembershipDetailsThirdGen from './MembershipDetailsThirdGen'

interface Props {
  memberships: Array<Membership>
}

export const MembershipCard: FC<Props> = ({ memberships }) => {
  const { t } = useTranslation()

  return (
    <Card
      title={
        <Typography.Title level={2}>
          {t('pages.caseReview.membershipDetails.title')}
        </Typography.Title>
      }
      data-test-id="membershipDetails"
      data-analytics-id="membership-details"
    >
      <ErrorBoundary message={t('errorBoundaries.membershipDetails')}>
        <Flex wrap gap={spacingSM}>
          {memberships.map((membership) => (
            <MembershipDetailsThirdGen
              key={membership.membershipId}
              membership={membership}
            />
          ))}
        </Flex>
      </ErrorBoundary>
    </Card>
  )
}

export default MembershipCard
