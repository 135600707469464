import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Descriptions } from 'antd'
import { InvestigationInfo, PREDICTION_LIST_TYPE } from '@signifyd/http'
import CopyWrapper from 'core/components/CopyWrapper'
import OrderAttributeLists from 'core/components/OrderAttributeLists'
import NoInformation from 'core/components/NoInformation'
import { defaultDescriptionProps } from 'core/constants'

interface Props {
  caseDetails: InvestigationInfo | null
}

const Device: FC<Props> = ({ caseDetails }) => {
  const { t } = useTranslation()

  const deviceId = caseDetails?.threatMetrixDeviceId

  return (
    <Descriptions
      title={t('pages.caseReview.details.device')}
      items={[
        deviceId && {
          label: t('pages.caseReview.details.deviceId'),
          children: (
            <>
              <CopyWrapper text={deviceId}>{deviceId}</CopyWrapper>
              <OrderAttributeLists
                type={PREDICTION_LIST_TYPE.DEVICE_ID}
                value={deviceId}
                analyticsId="device-ID"
              />
            </>
          ),
        },
        !deviceId && {
          label: '',
          labelStyle: { display: 'none' },
          children: <NoInformation />,
        },
      ].filter((item) => !!item)}
      {...defaultDescriptionProps}
    />
  )
}

export default Device
