export default {
  searchBar: {
    placeholder: 'Search and filter by order ID, case ID or shopper name',
    airlinePlaceholder:
      'Search and filter by order ID, case ID or record locator',
    eventTicketingPlaceholder:
      'Search and filter by order ID, case ID, shopper name or confirmation number',
    invalidSearch:
      'Input not valid. Please verify format or additional symbols * not valid at the start of a search term.',
  },
  quickSearch: {
    quickViews: 'Quick views',
    savedFilters: 'Saved filters',
    savedFiltersTooltip:
      'Save your most commonly used search and filter combinations.',
    savedFiltersDateNote:
      'Please note, that dates are not saved as part of saved filter criteria.',
    noSavedFilters: "You haven't saved any filters yet",
    'todays-orders': "Today's orders",
    'all-orders-last-fourteen-days': 'All orders from the last 14 days',
    'declined-last-7-days': 'Declined orders from the last 7 days',
    'claims-need-more-info': 'Claims that need more information',
  },
  footer: {
    apply: 'Apply search',
    applying: 'Applying',
    close: 'Close',
    saveFilter: {
      label: 'Save filter',
      placeholder: 'Give it a name (40 characters max)',
      nameExists: 'To save filter, try a name that has not been used before',
      noName: 'Please give it a name to save',
      noSpecialChar: 'To save new name, remove special characters',
      tooltip:
        "Date filter can't be saved on its own because the search input is empty",
    },
  },
  buttons: {
    cancel: 'Cancel',
    apply: 'Apply search',
    clearSearch: 'Clear search',
  },
  summary: {
    label: {
      keyword: 'Keyword search',
      quickView: 'Quick view',
      savedFilter: 'Saved filter',
    },
  },
}
