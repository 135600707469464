import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import { PhysicalAddress } from '@signifyd/http'
import { TextThirdGen } from '@signifyd/components'
import { Flex, Tooltip } from 'antd'
import { MENU_SECTION_TYPE } from 'pages/CaseReviewPage/containers/OrderDetails/components/CaseDetails/components/DeepLinkDropdown/DeepLinkDropdown.types'
import { spacingXS } from '@signifyd/ant'
import styles from './AddressField.less'

interface Props {
  type:
    | MENU_SECTION_TYPE.billingAddress
    | MENU_SECTION_TYPE.deliveryAddress
    | MENU_SECTION_TYPE.seller
  address?: PhysicalAddress
  normalizedAddress?: string
}

const AddressFieldThirdGen: FC<Props> = ({
  address,
  normalizedAddress,
  type,
}) => {
  const { t } = useTranslation()
  if (!address) {
    return null
  }

  const fullAddress = (
    <TextThirdGen data-test-id={`${type}FullAddress`} className={styles.text}>
      {address.streetAddress} {address.unit}
      {address.city}
      {address.city && ', '}
      {address.provinceCode}
      {address.provinceCode && ', '}
      {address.postalCode}
      {address.postalCode && ', '}
      {address.countryCode}
    </TextThirdGen>
  )

  const partialAddress = (
    <TextThirdGen
      data-test-id={`${type}PartialAddress`}
      className={styles.text}
    >
      {address.fullAddress}
      <br />
      {address.postalCode}
    </TextThirdGen>
  )

  const normalizedAddressTooltip = normalizedAddress ? (
    <Flex gap={spacingXS} data-test-id="normalizedAddressTooltip">
      <TextThirdGen className={styles.normalizedAddressTooltip}>
        {t(`pages.caseReview.details.normalizedAddress.${type}`)}
      </TextThirdGen>
      <TextThirdGen className={styles.normalizedAddressTooltip}>
        {normalizedAddress}
      </TextThirdGen>
    </Flex>
  ) : null

  return (
    <Tooltip
      overlayStyle={{ maxWidth: 'none' }}
      title={normalizedAddressTooltip}
      mouseEnterDelay={0.5}
      overlayClassName={cx(['ant-tooltip', styles.antTooltip])}
    >
      {address.streetAddress ? fullAddress : partialAddress}
    </Tooltip>
  )
}

export default AddressFieldThirdGen
