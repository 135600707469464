type PlaceholderKey =
  | 'airlinePlaceholder'
  | 'eventTicketingPlaceholder'
  | 'placeholder'

export const getPlaceholderText = (
  hasAirlineOrders?: boolean | null,
  hasEventTicketingOrders?: boolean | null
): PlaceholderKey => {
  if (hasEventTicketingOrders) {
    return 'eventTicketingPlaceholder'
  }

  if (hasAirlineOrders) {
    return 'airlinePlaceholder'
  }

  return 'placeholder'
}
