import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Descriptions } from 'antd'
import { Text } from '@signifyd/components'
import { InvestigationInfo, PREDICTION_LIST_TYPE } from '@signifyd/http'
import { CaseEntriesResolvedState } from 'store/currentCase/types/case.entries.types'
import CopyWrapper from 'core/components/CopyWrapper'
import NoInformation from 'core/components/NoInformation'
import { useToggle } from 'core/utils/useToggle'
import SeederLabel from 'pages/CaseReviewPage/components/SeederLabel'
import { isTransportAirline } from 'core/utils/isTransportAirline/isTransportAirline'
import { getShowHideItems } from 'pages/CaseReviewPage/containers/OrderDetails/OrderDetails.utils'
import OrderAttributeLists from 'core/components/OrderAttributeLists/OrderAttributeLists'
import { DescriptionsItemType } from 'antd/es/descriptions'
import { defaultDescriptionProps } from 'core/constants'
import DeepLinkDropdown from '../components/DeepLinkDropdown'
import {
  MENU_SECTION,
  MENU_SECTION_TYPE,
} from '../components/DeepLinkDropdown/DeepLinkDropdown.types'
import styles from '../CaseDetails.less'
import { useGetAirlinePassengers } from './useGetAirlinePassengers'

interface Props {
  caseEntries: CaseEntriesResolvedState
  caseDetails: InvestigationInfo
  accountHolderTaxInfo: {
    accountHolderTaxIdCountry?: string
    accountHolderTaxId?: string
  }
}

const People: FC<Props> = ({
  caseEntries,
  caseDetails,
  accountHolderTaxInfo,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'pages.caseReview.details',
  })

  const { cardHolder, deliveryRecipient, deliveryRecipientAddress, people } =
    caseEntries

  const isAirlineOrder = isTransportAirline(caseDetails.products)

  const { accountHolderTaxIdCountry, accountHolderTaxId } = accountHolderTaxInfo

  const { value: showAll, toggle: toggleShowAll } = useToggle()

  const ekataAddressOccupants =
    deliveryRecipientAddress?.details?.wpReverseAddressV3Model
      ?.current_residents ?? []

  const { maxCountItems, itemsToShow } = getShowHideItems({
    items: ekataAddressOccupants,
    showAll,
  })

  const noInfo =
    !cardHolder &&
    !deliveryRecipient &&
    !ekataAddressOccupants.length &&
    !accountHolderTaxIdCountry &&
    !accountHolderTaxId &&
    !people.length

  const sectionName = t('people')

  const airlinePassengers = useGetAirlinePassengers(people, isAirlineOrder)

  const peopleItems: Array<DescriptionsItemType> = [
    cardHolder?.entityName && {
      label: <SeederLabel label={t('cardHolder')} />,
      children: (
        <CopyWrapper text={cardHolder.entityName}>
          <DeepLinkDropdown
            section={MENU_SECTION.people}
            type={MENU_SECTION_TYPE.cardHolder}
          >
            {cardHolder.entityName}
          </DeepLinkDropdown>
        </CopyWrapper>
      ),
    },
    deliveryRecipient?.entityName && {
      label: <SeederLabel label={t('deliveryRecipient')} />,
      children: (
        <CopyWrapper text={deliveryRecipient.entityName}>
          <DeepLinkDropdown
            section={MENU_SECTION.people}
            type={MENU_SECTION_TYPE.deliveryRecipient}
          >
            {deliveryRecipient.entityName}
          </DeepLinkDropdown>
        </CopyWrapper>
      ),
    },
    accountHolderTaxId && {
      label: <SeederLabel label={t('accountHolderTaxId')} />,
      children: (
        <>
          <CopyWrapper text={accountHolderTaxId}>
            {accountHolderTaxId}
          </CopyWrapper>
          <OrderAttributeLists
            type={PREDICTION_LIST_TYPE.TAX_ID}
            value={accountHolderTaxId}
            analyticsId="tax-ID"
          />
        </>
      ),
    },
    accountHolderTaxIdCountry && {
      label: <SeederLabel label={t('accountHolderTaxIdCountry')} />,
      children: (
        <CopyWrapper text={accountHolderTaxIdCountry}>
          {accountHolderTaxIdCountry}
        </CopyWrapper>
      ),
    },
    ...itemsToShow.map((occupant) => ({
      label: t('addressOccupants.label'),
      children: (
        <CopyWrapper text={occupant.name}>
          <DeepLinkDropdown
            section={MENU_SECTION.occupants}
            type={MENU_SECTION_TYPE.addressOccupant}
            value={occupant.name}
          >
            {occupant.name}
          </DeepLinkDropdown>
          {occupant.age_range && (
            <>
              <br />
              <Text>
                {t('addressOccupants.age', {
                  ageRange: occupant.age_range,
                })}
              </Text>
            </>
          )}
          {occupant.gender && (
            <>
              <br />
              <Text>
                {t('addressOccupants.gender', {
                  gender: occupant.gender,
                })}
              </Text>
            </>
          )}
          {occupant.phones?.map((phoneDetails) => (
            <Fragment key={phoneDetails.id}>
              <br />
              <Text>
                {t('addressOccupants.phone', {
                  phoneNumber: phoneDetails.phone_number,
                })}
              </Text>
            </Fragment>
          ))}
        </CopyWrapper>
      ),
    })),
    !!maxCountItems && {
      label: '',
      children: (
        <Button
          className={styles.showAllButton}
          onClick={toggleShowAll}
          type="link"
        >
          {showAll
            ? t('addressOccupants.showLessOccupants')
            : t('addressOccupants.showAllOccupants')}
        </Button>
      ),
    },
    ...airlinePassengers,
    noInfo && {
      label: '',
      labelStyle: { display: 'none' },
      children: <NoInformation />,
    },
  ].filter((item) => !!item) as Array<DescriptionsItemType>

  return (
    <>
      <Descriptions
        title={sectionName}
        items={peopleItems}
        {...defaultDescriptionProps}
      />
    </>
  )
}

export default People
