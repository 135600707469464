import { FC, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { INV_REVIEW_DISPOSITION, InvestigationInfo } from '@signifyd/http'
import { Modal } from 'antd'
import {
  NoteTextArea,
  Text,
  useFileAttacher,
  useIsThirdGen,
} from '@signifyd/components'
import { UploadFile } from 'antd/lib/upload/interface'
import { COMMON_MODAL_PROPS, maxNoteLength } from 'core/constants'
import { FileNoteUpdate } from 'store/currentCase/types/case.notes.types'
import { useCaseFeedback } from 'core/hooks/useCaseFeedback'

interface Props {
  showModal: boolean
  caseDetails: InvestigationInfo
  setShowModal: (show: boolean) => void
}

export const FeedbackReasonModal: FC<Props> = ({
  showModal,
  caseDetails,
  setShowModal,
}) => {
  const { t } = useTranslation()
  const isThirdGen = useIsThirdGen()

  // Local State
  const [note, setNote] = useState('')
  const modalRef = useRef(null)

  const fileAttacher = useFileAttacher()
  const { clearFiles, files } = fileAttacher

  const { isLoading, mutate } = useCaseFeedback()

  // Handlers
  const closeModal = (): void => {
    setNote('')
    clearFiles()
    setShowModal(false)
  }

  const onSubmitHandler = async (): Promise<void> => {
    const notes: FileNoteUpdate = {
      caseId: caseDetails.investigationId,
      text: note,
      files,
    }

    mutate({
      caseDetails,
      feedback: INV_REVIEW_DISPOSITION.GOOD,
      notes,
    })
  }

  const filesHasNoneSuccess = files.some(({ status }: UploadFile) => {
    return status !== 'done'
  })
  const noteTooLong = note.length > maxNoteLength
  const isSubmitDisabled = !note.trim() || noteTooLong || filesHasNoneSuccess

  return (
    <Modal
      {...COMMON_MODAL_PROPS}
      closable
      confirmLoading={isLoading}
      title={t('caseActions.feedbackNote.title')}
      open={showModal}
      okType={isThirdGen ? 'primary' : 'link'}
      okButtonProps={{ disabled: isSubmitDisabled }}
      okText={t('caseActions.feedbackNote.okText')}
      onOk={onSubmitHandler}
      onCancel={closeModal}
    >
      <Text>
        {t('caseActions.feedbackNote.info')}
        <Text weight="semibold">{t('caseActions.feedbackReasons.GOOD')}</Text>
      </Text>
      <NoteTextArea
        maxLength={1000}
        note={note}
        fileAttacher={fileAttacher}
        onChange={setNote}
        disabled={isLoading}
        placeholder={t('caseActivity.addNotesPlaceholder')}
        attachFileButtonAnalyticsId="feedback-reason-modal-attach-file-button"
        textAreaAnalyticsId="feedback-reason-modal-text-area"
        ref={modalRef}
      />
    </Modal>
  )
}

export default FeedbackReasonModal
