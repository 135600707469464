import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { InvestigationInfo, PhysicalAddress } from '@signifyd/http'
import { COUNTRY_ISO } from '@signifyd/utils'
import { Descriptions } from 'antd'
import CopyWrapper from 'core/components/CopyWrapper'
import NoInformation from 'core/components/NoInformation'
import {
  findRoleInSection,
  findRolesMatchInSection,
} from 'store/currentCase/utils/case.entries.utils'
import { isTransportAirline } from 'core/utils/isTransportAirline/isTransportAirline'
import { useCaseEntries } from 'core/hooks/useCaseEntries'
import isEventTicketing from 'core/utils/isEventTicketing'
import EventAddresses from 'pages/CaseReviewPage/containers/OrderDetails/components/CaseDetails/Locations/EventAddresses'
import EventVenueLocations from 'pages/CaseReviewPage/containers/OrderDetails/components/CaseDetails/Locations/EventVenueLocations'
import IPGeo from './IPGeo'
import AddressRow from './AddressRow'
import { processUniqueAddresses } from './Locations.utils'
import AirlineLocations from './AirlineLocations'

const { Item: DescriptionItem } = Descriptions

export interface LocationsProps {
  caseDetails: InvestigationInfo | null
}

const Locations: FC<LocationsProps> = ({ caseDetails }) => {
  const { t } = useTranslation()
  const { data: caseEntries } = useCaseEntries(caseDetails?.investigationId)

  if (!caseEntries || !caseDetails) {
    return <NoInformation />
  }

  const isAirlineOrder = isTransportAirline(caseDetails.products)
  const isEventTicketingOrder = isEventTicketing(caseDetails.products)

  const creditCardIssuer = findRoleInSection(
    caseEntries.organizations,
    'creditCardIssuer'
  )

  const locationIPGeo = findRoleInSection(
    caseEntries.physicalLocations,
    'ipGeo'
  )

  const allDeliveryAddresses = findRolesMatchInSection(
    caseEntries.physicalLocations,
    'deliveryAddress'
  )
  const cardHolderAddress = findRoleInSection(
    caseEntries.physicalLocations,
    'billingAddress'
  )

  const airportLocations = caseEntries.physicalLocations.filter(({ role }) =>
    role.includes('flightLeg')
  )

  const countryIsoCode = creditCardIssuer?.details
    ?.countryIsoCode as keyof typeof COUNTRY_ISO

  const uniqueAddresses = caseDetails ? processUniqueAddresses(caseDetails) : []
  const hasDeliveryAddresses = uniqueAddresses.length > 0

  const hasCountryIsoOrIPGeo = !!countryIsoCode || !!locationIPGeo
  const noInfo = !hasDeliveryAddresses && !hasCountryIsoOrIPGeo

  const eventAddresses = caseDetails.products.reduce<Array<PhysicalAddress>>(
    (addresses, product) => {
      if (product.eventTicketing?.eventVenueAddress) {
        addresses.push(product.eventTicketing.eventVenueAddress)
      }

      return addresses
    },
    []
  )

  const eventVenueNames = caseDetails.products
    .map((product) => product.eventTicketing?.eventVenueName)
    .filter((name) => name !== undefined)

  return (
    <div id="locations">
      <Descriptions
        title={t('pages.caseReview.details.locations')}
        colon={false}
        size="small"
      />

      {/* UNIQUE ADDRESSES */}
      {hasDeliveryAddresses &&
        uniqueAddresses.map((uniqueAddress) => (
          <AddressRow
            key={`${uniqueAddress.type}-${uniqueAddress.address.fullAddress}`}
            uniqueAddress={uniqueAddress}
            allDeliveryAddresses={allDeliveryAddresses}
            cardHolderAddress={cardHolderAddress}
          />
        ))}

      {/* EVENT ADDRESSES */}
      {isEventTicketingOrder && (
        <EventAddresses eventTicketingAddresses={eventAddresses} />
      )}

      {/* IP LOCATIONS & ISO COUNTRY CODES */}
      {hasCountryIsoOrIPGeo && (
        <Descriptions colon={false} size="small">
          {countryIsoCode && (
            <DescriptionItem
              label={t('pages.caseReview.details.creditCardIssuanceLocation')}
            >
              <CopyWrapper
                text={`${COUNTRY_ISO[countryIsoCode]} (${countryIsoCode})`}
              >
                {`${COUNTRY_ISO[countryIsoCode]} (${countryIsoCode})`}
              </CopyWrapper>
            </DescriptionItem>
          )}
          {locationIPGeo?.entityName && (
            <DescriptionItem label={t('pages.caseReview.details.ipGeo')}>
              <CopyWrapper text={locationIPGeo.entityName}>
                <IPGeo ipGeo={locationIPGeo} />
              </CopyWrapper>
            </DescriptionItem>
          )}
        </Descriptions>
      )}

      {isAirlineOrder && !!airportLocations?.length && (
        <AirlineLocations airportLocations={airportLocations} />
      )}

      {/* VENUE LOCATIONS */}
      {isEventTicketingOrder && eventVenueNames.length > 0 && (
        <EventVenueLocations eventVenueNames={eventVenueNames} />
      )}

      {noInfo && <NoInformation />}
    </div>
  )
}

export default Locations
