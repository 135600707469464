import { EventTicketingEventType } from '@signifyd/http/dist/v2/investigations/types/investigation/eventTicketing'

const eventTypeValues: Record<EventTicketingEventType, string> = {
  CONCERT: 'Concert',
  SPORTS: 'Sports',
  THEATER: 'Theater',
  EXCURSION: 'Excursion',
  TRANSIT: 'Transit',
}

export default eventTypeValues
