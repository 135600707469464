import { useTranslation } from 'react-i18next'
import { Button } from 'antd'
import { CaseEntriesItem, CaseEntryPersonDetails } from '@signifyd/http'
import CopyWrapper from 'core/components/CopyWrapper'
import { useToggle } from 'core/utils/useToggle'
import SeederLabel from 'pages/CaseReviewPage/components/SeederLabel'
import { getShowHideItems } from 'pages/CaseReviewPage/containers/OrderDetails/OrderDetails.utils'
import { DescriptionsItemType } from 'antd/es/descriptions'
import { MENU_SECTION_TYPE } from '../components/DeepLinkDropdown/DeepLinkDropdown.types'
import styles from '../CaseDetails.less'

type Passenger = CaseEntriesItem<CaseEntryPersonDetails>

export const useGetAirlinePassengers = (
  people: Array<Passenger>,
  isAirlineOrder: boolean
): Array<DescriptionsItemType> => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'pages.caseReview.details',
  })
  const { value: showAll, toggle: toggleShowAll } = useToggle()

  const leadPassenger = people.find(
    (passenger) => passenger.role === MENU_SECTION_TYPE.leadPassenger
  )

  const passengers = people.filter(
    (passenger) => passenger.role === MENU_SECTION_TYPE.passenger
  )

  const { maxCountItems, itemsToShow } = getShowHideItems({
    items: passengers,
    showAll,
  })

  if (!isAirlineOrder) {
    return []
  }

  return [
    leadPassenger?.entityName && {
      label: <SeederLabel label={t('leadPassenger')} />,
      children: (
        <CopyWrapper text={leadPassenger.entityName}>
          {leadPassenger.entityName}
        </CopyWrapper>
      ),
    },
    ...itemsToShow.map((passenger) => ({
      label: <SeederLabel label={t('passenger')} />,
      children: (
        <CopyWrapper text={passenger.entityName}>
          {passenger.entityName}
        </CopyWrapper>
      ),
    })),
    !!maxCountItems && {
      label: '',
      children: (
        <Button
          className={styles.showAllButton}
          onClick={toggleShowAll}
          type="link"
        >
          {showAll ? t('showLessPassengers') : t('showAllPassengers')}
        </Button>
      ),
    },
  ] as Array<DescriptionsItemType>
}
