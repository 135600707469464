import { FC, useState } from 'react'
import { colorSlate } from '@signifyd/colors'
import { Dropdown } from 'antd'
import { DollarOutlined } from '@signifyd/components'
import { ClaimReimbursementRequiredFields } from 'core/hooks/caseActions/useClaimReimbursement/types'
import MoreOptionsClaimReimbursementButton from 'pages/CaseReviewPage/components/ClaimReimbursementButton/MoreOptionsClaimReimbursementButton'
import { useUserHasReviewerRole } from 'core/hooks/userHasReviewerRole'
import styles from './ThirdGenMoreOptionsFlyout.less'

interface Props {
  caseDetails: ClaimReimbursementRequiredFields
}

const ThirdGenMoreOptionsFlyout: FC<Props> = ({ caseDetails }) => {
  const [active, setActive] = useState(false)

  const hasReviewerRole = useUserHasReviewerRole(caseDetails.teamId)

  if (hasReviewerRole) {
    return null
  }

  return (
    <Dropdown
      onOpenChange={setActive}
      menu={{
        items: [
          {
            key: 'claimReimbursementButton',
            onClick: () => setActive(false),
            label: (
              <MoreOptionsClaimReimbursementButton caseDetails={caseDetails} />
            ),
          },
        ],
      }}
      open={active}
      placement="bottomLeft"
      trigger={['click', 'contextMenu']}
    >
      <div data-test-id="moreOptionsFlyout">
        <DollarOutlined fill={colorSlate} className={styles.icon} />
      </div>
    </Dropdown>
  )
}

export default ThirdGenMoreOptionsFlyout
