import { PREDICTION_LIST_TYPE } from '@signifyd/http'
import { Text, Space, useIsThirdGen, TextThirdGen } from '@signifyd/components'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { Loading3QuartersOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Button, Flex, Popover, Tooltip } from 'antd'
import { FC, useState } from 'react'
import { useStoreActions, useStoreState } from 'store'

import { spacingSM } from '@signifyd/ant'
import styles from './OrderAttributeLists.less'
import {
  useGetListData,
  useAddToListMutation,
  useRemoveFromListMutation,
  ListData,
} from './useGetOrderAttributeLists'
import ListTag from './ListTag'
import OrderAttributeListItem from './OrderAttributeListsItem'

interface Props {
  analyticsId: string
  type: PREDICTION_LIST_TYPE
  value: string | undefined
}

const OrderAttributeLists: FC<Props> = ({ value, type, analyticsId }) => {
  const { t } = useTranslation()
  const isThirdGen = useIsThirdGen()
  const { addNote } = useStoreActions((actions) => actions.currentCase)
  const { investigationId } = useStoreState(
    (state) => state.currentCase.details!
  )
  const { teamId } = useStoreState((state) => state.currentCase.details!)
  const [isAddPopoverVisible, setAddToListPopoverVisible] = useState(false)

  const {
    data,
    isLoading: isGetListLoading,
    isError: isGetListError,
  } = useGetListData({
    value,
    type,
    teamId,
  })

  const { isLoading: isAddLoading, mutateAsync: addToList } =
    useAddToListMutation({ value, type, teamId, addNote, investigationId })

  const { isLoading: isRemoveLoading, mutateAsync: removeFromList } =
    useRemoveFromListMutation({
      value,
      type,
      teamId,
      addNote,
      investigationId,
    })

  const { contains, excludes } = data as ListData

  if (!value || isGetListError || (!contains.length && !excludes.length)) {
    return null
  }

  const isLoading = isGetListLoading || isAddLoading || isRemoveLoading

  return (
    <Flex wrap className={styles.listWrapper}>
      <div>
        {isLoading && (
          <Loading3QuartersOutlined spin className={styles.loadingIcon} />
        )}

        {contains.length > 0 && (
          <div className={styles.tagContainer}>
            <Text className={styles.usedInListText} data-test-id="usedInList">
              {t('pages.caseReview.details.usedInList')}
            </Text>{' '}
            {contains.map((item) => (
              <ListTag
                item={item}
                key={item.id}
                deleteFromList={() =>
                  removeFromList({ list: item, value }).then(() =>
                    setAddToListPopoverVisible(false)
                  )
                }
                analyticsId={analyticsId}
              />
            ))}
          </div>
        )}

        {excludes.length > 0 && (
          <Popover
            overlayClassName={cx(['sig-popconfirm', styles.popconfirm])}
            data-test-id="confirmDeletePopover"
            title={
              isThirdGen ? (
                <TextThirdGen>
                  {t('orderAttributeLists.availableListsTitle')}
                </TextThirdGen>
              ) : (
                <div className={styles.popoverHeader}>
                  {t('orderAttributeLists.availableListsTitle')}
                </div>
              )
            }
            placement="bottomRight"
            trigger="click"
            open={isAddPopoverVisible}
            onOpenChange={(visible) => setAddToListPopoverVisible(visible)}
            getPopupContainer={(triggerNode: HTMLElement) =>
              triggerNode.parentNode as HTMLElement
            }
            content={
              <>
                {excludes.map((item) => (
                  <Flex
                    key={item.id}
                    className={styles.popoverItem}
                    justify="space-between"
                    gap={spacingSM}
                    onClick={() =>
                      addToList({ list: item, value, type }).then(() =>
                        setAddToListPopoverVisible(false)
                      )
                    }
                    data-test-id={`listNotContainingAttribute-${item.id}`}
                  >
                    {isThirdGen ? (
                      <OrderAttributeListItem item={item} />
                    ) : (
                      <>
                        <div className={styles.itemName}>{item.name}</div>
                        <div className={styles.itemCount}>
                          {t('pages.caseReview.details.itemsCount', {
                            count: item.size,
                          })}
                        </div>
                      </>
                    )}
                  </Flex>
                ))}
                <Space size={11} />
              </>
            }
          >
            <Tooltip
              title={t('orderAttributeLists.addToListButtonTooltip')}
              placement="topRight"
            >
              <Button
                className={styles.addToListBtn}
                data-test-id="addToListButton"
                data-analytics-id={`add-to-list-for-${analyticsId}`}
                type={isThirdGen ? 'link' : 'default'}
              >
                <PlusCircleOutlined />
                <Text>{t('pages.caseReview.details.addToListButton')}</Text>
              </Button>
            </Tooltip>
          </Popover>
        )}
      </div>
    </Flex>
  )
}

export default OrderAttributeLists
