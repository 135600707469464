import { FC, useState } from 'react'
import { Tabs } from 'antd'
import { StretchToPageBottom, useIsThirdGen } from '@signifyd/components'
import { useTranslation } from 'react-i18next'
import { useStoreState } from 'store'
import type { TabsProps } from 'antd'
import styles from './TabbedCaseDisplay.less'
import OrderDetails from '../OrderDetails'
import ReturnDetails from '../ReturnDetails'
import ReturnDetailsThirdGen from '../ReturnDetails/thirdGen/ReturnDetailsThirdGen'

type CaseTabs = 'order' | 'returns'

const TabbedCaseDisplay: FC = () => {
  const { t } = useTranslation()
  const hasReturns = useStoreState(
    (store) => !!store.currentCase.details?.returns?.length
  )
  const [tab, setTab] = useState<CaseTabs>('order')
  const isThirdGen = useIsThirdGen()

  const items: TabsProps['items'] = [
    {
      key: 'order',
      label: t('pages.caseReview.caseTabs.order'),
      children: (
        <StretchToPageBottom className={styles.tabContent}>
          <OrderDetails />
        </StretchToPageBottom>
      ),
      // required for stretch to page bottom to work
      destroyInactiveTabPane: true,
    },
    hasReturns && {
      key: 'return',
      label: t('pages.caseReview.caseTabs.return'),
      children: (
        <StretchToPageBottom className={styles.tabContent}>
          {isThirdGen ? <ReturnDetailsThirdGen /> : <ReturnDetails />}
        </StretchToPageBottom>
      ),
      // required for stretch to page bottom to work
      destroyInactiveTabPane: true,
    },
  ].filter((tab) => !!tab) as TabsProps['items']

  return (
    <Tabs
      activeKey={tab}
      items={items}
      onTabClick={(activeKey) => {
        setTab(activeKey as CaseTabs)
      }}
      className={styles.tabs}
    />
  )
}

export default TabbedCaseDisplay
