import {
  CaseEntriesItem,
  CaseEntryLocationDetails,
  PREDICTION_LIST_TYPE,
  PhysicalAddress,
} from '@signifyd/http'
import { toTitleCase } from '@signifyd/utils'
import CopyWrapper from 'core/components/CopyWrapper'
import OrderAttributeLists from 'core/components/OrderAttributeLists'
import AddressField from 'pages/CaseReviewPage/components/AddressField'
import { DescriptionsItemType } from 'antd/es/descriptions'
import { i18nInstance } from '@signifyd/components'
import DeepLinkDropdown from '../components/DeepLinkDropdown'
import AddressInsight from './AddressInsight'
import {
  MENU_SECTION,
  MENU_SECTION_TYPE,
} from '../components/DeepLinkDropdown/DeepLinkDropdown.types'
import { UniqueAddress } from './Locations.utils'
import EnhancedSeederLabel from '../components/EnhancedSeederLabel'
import MatchedAddressInsight from './MatchedAddressInsight'

const { t } = i18nInstance

export interface AddressRowProps {
  uniqueAddress: UniqueAddress
  allDeliveryAddresses?: Array<CaseEntriesItem<CaseEntryLocationDetails>>
  cardHolderAddress?: CaseEntriesItem<CaseEntryLocationDetails>
}

const formatDeliveryAddress = (address: PhysicalAddress): string => {
  const { streetAddress, unit, city, provinceCode, postalCode, countryCode } =
    address

  return [streetAddress, unit, city, provinceCode, postalCode, countryCode]
    .filter(Boolean)
    .join(' ')
}

const getAddressRow = ({
  uniqueAddress,
  allDeliveryAddresses,
  cardHolderAddress,
}: AddressRowProps): DescriptionsItemType => {
  const { address, shipments, type, normalizedAddress } = uniqueAddress

  return {
    label: (
      <EnhancedSeederLabel
        label={t(`pages.caseReview.details.${type}Address`)}
        values={shipments.map((shipment) =>
          toTitleCase(
            shipment.shippingMethod || t('filters.shippingMethod.OTHER')
          )
        )}
      />
    ),
    children: (
      <>
        <CopyWrapper text={address.fullAddress}>
          <DeepLinkDropdown
            section={MENU_SECTION.address}
            type={type}
            value={address.fullAddress}
          >
            <AddressField
              type={type}
              address={address}
              normalizedAddress={normalizedAddress}
            />
          </DeepLinkDropdown>
          {type === MENU_SECTION_TYPE.billingAddress && (
            <AddressInsight
              isReceivingMail={cardHolderAddress?.details?.isReceivingMail}
              deliveryPoint={cardHolderAddress?.details?.deliveryPoint}
            />
          )}
          {type === MENU_SECTION_TYPE.deliveryAddress && (
            <MatchedAddressInsight
              recipientAddress={address}
              deliveryAddresses={allDeliveryAddresses}
            />
          )}
        </CopyWrapper>
        <OrderAttributeLists
          value={formatDeliveryAddress(address)}
          type={PREDICTION_LIST_TYPE.ADDRESS}
          analyticsId="account-location"
        />
      </>
    ),
  }
}

export default getAddressRow
