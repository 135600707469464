import { SigTag, i18nInstance, TextThirdGen } from '@signifyd/components'
import { colorMarengo } from '@signifyd/colors'
import { AirlineTripDetail } from '@signifyd/http'
import { spacingMD, spacingSM } from '@signifyd/ant'
import { Flex } from 'antd'
import styles from './AirlineTripDetails.less'

const { t } = i18nInstance

const PanelHeader = ({
  departurePortCode,
  arrivalPortCode,
  bookingClass,
  currency,
  slicePrice,
}: AirlineTripDetail): JSX.Element => (
  <Flex align="center" wrap gap={spacingMD}>
    <TextThirdGen
      data-test-id={`airlineTripHeader-${departurePortCode}-${arrivalPortCode}`}
      weight="semibold"
    >
      {t('airline.airlineTrip', {
        departurePortCode: departurePortCode?.toUpperCase(),
        arrivalPortCode: arrivalPortCode?.toUpperCase(),
      })}
    </TextThirdGen>
    <Flex gap={spacingSM}>
      {bookingClass && (
        <SigTag data-test-id="bookingClass" color={colorMarengo} type="primary">
          <TextThirdGen size="sm" className={styles.bookingClass}>
            {t('airline.bookingClass', {
              context: bookingClass,
            })}
          </TextThirdGen>
        </SigTag>
      )}
      <SigTag data-test-id="currencyPrice" color={colorMarengo} type="primary">
        <TextThirdGen size="sm" className={styles.currencyPrice}>
          {currency} {slicePrice?.toLocaleString()}
        </TextThirdGen>
      </SigTag>
    </Flex>
  </Flex>
)

export default PanelHeader
