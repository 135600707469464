import { Product } from '@signifyd/http'

export const isEventTicketing = (products: Array<Product>): boolean => {
  const hasEventTicketing = products?.some(
    (product) =>
      product?.eventTicketing?.eventVenueId != null ||
      product?.eventTicketing?.eventType != null
  )

  return hasEventTicketing ?? false
}
