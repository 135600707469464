import { ExperimentFilled, QuestionCircleOutlined } from '@ant-design/icons'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { Tooltip } from 'antd'
import { MouseEventHandler } from 'react'
import { ColumnProps } from 'antd/lib/table'
import {
  DECISION_MECHANISM,
  INV_REVIEW_DISPOSITION,
  InvSummaryViewInfo,
  SEARCH_FIELD,
} from '@signifyd/http'
import i18n from 'i18next'
import { i18nInstance, CheckoutTag } from '@signifyd/components'
import { formatCurrencyNumber } from '@signifyd/utils'
import { colorSlate } from '@signifyd/colors'
import RecommendationTag from 'core/components/RecommendationTag'
import GuaranteeTag from 'core/components/GuaranteeTag'
import { displayShippingMethod } from 'core/utils/textTransforms'
import FormattedDate from 'core/components/FormattedDate'
import AdditionalValues from 'core/components/AdditionalValues'
import { SortState } from 'store/search'
import ReturnTag from 'core/components/ReturnTag'
import TextOverflowWrapper from 'core/components/TextOverflowWrapper'
import MultiItemPopover from 'pages/SearchResultsPage/components/SearchResultsTable/MultiItemPopover'
import styles from './SearchResultsTable.less'
import MoreOptionsFlyout from './MoreOptionsFlyout'

// Note: If you find you need this, we should consider refactoring how this works.
// This was originally created to prevent antd from hijacking clicks inside of popovers.
// It would not allow a user to left-click-and-select text without hijacking that event
// and routing them as if the row had been clicked. After some research, this appears to
// be the most obvious fix for that problem. However, it can become a mess if we have to
// use it in more than one place. So proceed with a refactoring in mind. kthx.
const popoverOnClickHandler: MouseEventHandler = (e) => {
  const eventTarget = e.target as HTMLElement
  const containsClass = eventTarget.parentElement?.closest(
    '.ant-popover-content'
  )
  if (containsClass) {
    return e.stopPropagation()
  }

  return undefined
}

const sortableColumns: Array<SEARCH_FIELD> = [
  SEARCH_FIELD.normalizedPurchaseCreatedAt,
  SEARCH_FIELD.orderTotalAmount,
  SEARCH_FIELD.signifydScore,
]
interface Args {
  loading?: boolean
  smallSize: boolean
  sort: SortState
  hiddenCols: Set<SEARCH_FIELD>
  hasAirlineOrders: boolean
  hasEventTicketingOrders: boolean
}

export const getColumnConfig = ({
  loading,
  smallSize,
  sort,
  hiddenCols,
  hasAirlineOrders,
  hasEventTicketingOrders,
}: Args): Array<ColumnProps<InvSummaryViewInfo>> => {
  if (loading) {
    return []
  }

  const SHOPPER_AND_PASSENGER_CHAR_LIMIT = 46
  const SHOPPER_AND_PASSENGER_MAX_WIDTH = 275

  const columnsConfigs: Record<string, ColumnProps<InvSummaryViewInfo>> = {
    recommendation:
      // Fixed Left Columns
      {
        // Note - These 5% widths are to force the table to not space the columns responsively.
        width: '5%',
        title: (
          <TextOverflowWrapper
            data-test-id={SEARCH_FIELD.recommendedDecisionDisposition}
          >
            {i18nInstance.t('pages.results.table.header.recommendation')}
          </TextOverflowWrapper>
        ),
        render(_t, { recommendedDecisionDisposition, decisionMechanism }) {
          if (
            decisionMechanism === DECISION_MECHANISM.POLICY_ONLY_ACCEPT ||
            !recommendedDecisionDisposition
          ) {
            return null
          }

          return (
            <TextOverflowWrapper>
              <RecommendationTag
                tooltipPlacement="right"
                recommendedDecisionDisposition={recommendedDecisionDisposition}
                showText={!smallSize}
              />
            </TextOverflowWrapper>
          )
        },
        key: SEARCH_FIELD.recommendedDecisionDisposition,
        fixed: 'left',
      },
    guaranteeDisposition: {
      width: '5%',
      title: (
        <TextOverflowWrapper data-test-id={SEARCH_FIELD.guaranteeDisposition}>
          {i18nInstance.t('pages.results.table.header.guarantee')}
        </TextOverflowWrapper>
      ),
      render(_t, { investigationStatus, guaranteeDisposition }) {
        return investigationStatus === 'HELD' ? null : (
          <TextOverflowWrapper>
            <GuaranteeTag
              disposition={guaranteeDisposition}
              showText={!smallSize}
            />
          </TextOverflowWrapper>
        )
      },
      key: SEARCH_FIELD.guaranteeDisposition,
      fixed: 'left',
    },
    signifydScore: {
      width: '5%',
      title: (
        <TextOverflowWrapper data-test-id={SEARCH_FIELD.signifydScore}>
          {i18nInstance.t('pages.results.table.header.score')}
        </TextOverflowWrapper>
      ),
      render(_t, { signifydScore }) {
        return (
          <TextOverflowWrapper>
            {signifydScore && Math.trunc(signifydScore)}
          </TextOverflowWrapper>
        )
      },
      key: SEARCH_FIELD.signifydScore,
      fixed: !smallSize && 'left',
    },
    investigationId: {
      width: '5%',
      title: (
        <TextOverflowWrapper data-test-id={SEARCH_FIELD.investigationId}>
          {i18nInstance.t('pages.results.table.header.caseId')}
        </TextOverflowWrapper>
      ),
      render(_t, { investigationId }) {
        return <TextOverflowWrapper>{investigationId}</TextOverflowWrapper>
      },
      key: SEARCH_FIELD.investigationId,
      fixed: !smallSize && 'left',
    },
    orderId: {
      width: '5%',
      title: (
        <TextOverflowWrapper data-test-id={SEARCH_FIELD.orderId}>
          {i18nInstance.t('pages.results.table.header.orderId')}
        </TextOverflowWrapper>
      ),
      render(_t, { orderExternalId }) {
        return (
          <TextOverflowWrapper
            title={orderExternalId}
            maxWidth={smallSize ? 80 : 150}
          >
            {orderExternalId}
          </TextOverflowWrapper>
        )
      },
      key: SEARCH_FIELD.orderId,
      fixed: !smallSize && 'left',
    },
    eventId: {
      width: '5%',
      title: (
        <TextOverflowWrapper data-test-id={SEARCH_FIELD.eventId}>
          {i18nInstance.t('pages.results.table.header.confirmationNumber')}
        </TextOverflowWrapper>
      ),
      render(_t, { products }) {
        if (!products?.[0]?.eventTicketing?.eventType) {
          return null
        }

        const itemIds = products.map((product) => product.itemId)

        return (
          <MultiItemPopover
            items={itemIds}
            title={i18nInstance.t('pages.results.columnSelect.keys.eventId')}
          />
        )
      },
      key: SEARCH_FIELD.eventId,
    },
    eventName: {
      width: '5%',
      title: (
        <TextOverflowWrapper data-test-id={SEARCH_FIELD.eventName}>
          {i18nInstance.t('pages.results.table.header.eventName')}
        </TextOverflowWrapper>
      ),
      render(_t, { products }) {
        if (!products?.[0]?.eventTicketing?.eventType) {
          return null
        }

        return (
          <TextOverflowWrapper>{products?.[0]?.itemName}</TextOverflowWrapper>
        )
      },
      key: SEARCH_FIELD.eventName,
    },
    eventType: {
      width: '5%',
      title: (
        <TextOverflowWrapper data-test-id={SEARCH_FIELD.eventType}>
          {i18nInstance.t('pages.results.table.header.eventType')}
        </TextOverflowWrapper>
      ),
      render(_t, { products }) {
        const eventType = products?.[0]?.eventTicketing?.eventType

        if (!eventType) {
          return null
        }

        return (
          <TextOverflowWrapper>
            {i18nInstance.t(`eventTicketing.eventTypes.${eventType}`)}
          </TextOverflowWrapper>
        )
      },
      key: SEARCH_FIELD.eventType,
    },
    eventVenueName: {
      width: '5%',
      title: (
        <TextOverflowWrapper data-test-id={SEARCH_FIELD.eventVenueName}>
          {i18nInstance.t('pages.results.table.header.eventVenueName')}
        </TextOverflowWrapper>
      ),
      render(_t, { products }) {
        return (
          <TextOverflowWrapper>
            {products?.[0]?.eventTicketing?.eventVenueName}
          </TextOverflowWrapper>
        )
      },
      key: SEARCH_FIELD.eventVenueName,
    },
    recordLocator: {
      // Note - These 5% widths are to force the table to not space the columns responsively.
      width: '5%',
      title: (
        <TextOverflowWrapper data-test-id={SEARCH_FIELD.recordLocator}>
          {i18nInstance.t('pages.results.table.header.recordLocator')}
        </TextOverflowWrapper>
      ),
      render(_t, { products }) {
        return (
          <TextOverflowWrapper>{products?.[0]?.itemId}</TextOverflowWrapper>
        )
      },
      key: SEARCH_FIELD.recordLocator,
      fixed: 'left',
    },

    // Scrollable Columns
    guaranteeRecommendedAction: {
      width: '5%',
      title: (
        <TextOverflowWrapper
          data-test-id={SEARCH_FIELD.guaranteeRecommendedAction}
        >
          {i18nInstance.t('pages.results.table.header.checkout')}
        </TextOverflowWrapper>
      ),
      render(_t, caseDetails) {
        return (
          <TextOverflowWrapper>
            <CheckoutTag investigationInfoPolicyDetails={caseDetails} />
          </TextOverflowWrapper>
        )
      },
      key: SEARCH_FIELD.guaranteeRecommendedAction,
    },
    returnDecisionCheckpointAction: {
      width: '5%',
      title: (
        <TextOverflowWrapper
          data-test-id={SEARCH_FIELD.returnDecisionCheckpointAction}
        >
          {i18nInstance.t('pages.results.table.header.return')}
        </TextOverflowWrapper>
      ),
      render(_t, { returns }) {
        return (
          <TextOverflowWrapper>
            <ReturnTag returns={returns} />
          </TextOverflowWrapper>
        )
      },
      key: SEARCH_FIELD.returnDecisionCheckpointAction,
    },
    recipientFullName: {
      width: '5%',
      title: (
        <TextOverflowWrapper
          data-test-id={SEARCH_FIELD.recipientFullName}
          maxWidth={SHOPPER_AND_PASSENGER_MAX_WIDTH}
        >
          {i18nInstance.t('pages.results.table.header.recipientFullName')}
        </TextOverflowWrapper>
      ),
      render(_t, { headline }) {
        return (
          <TextOverflowWrapper
            maxWidth={SHOPPER_AND_PASSENGER_MAX_WIDTH}
            title={
              headline?.length > SHOPPER_AND_PASSENGER_CHAR_LIMIT
                ? headline
                : undefined
            }
          >
            {headline}
          </TextOverflowWrapper>
        )
      },
      key: SEARCH_FIELD.recipientFullName,
    },
    normalizedPurchaseCreatedAt: {
      width: '5%',
      title: (
        <TextOverflowWrapper
          data-test-id={SEARCH_FIELD.normalizedPurchaseCreatedAt}
        >
          {i18nInstance.t('pages.results.table.header.date')}
        </TextOverflowWrapper>
      ),
      render(_t, { normalizedPurchaseCreatedAt, createdAt }) {
        return (
          <TextOverflowWrapper>
            <FormattedDate date={normalizedPurchaseCreatedAt || createdAt} />
          </TextOverflowWrapper>
        )
      },
      key: SEARCH_FIELD.normalizedPurchaseCreatedAt,
    },
    orderTotalAmount: {
      width: '5%',
      title: (
        <TextOverflowWrapper data-test-id={SEARCH_FIELD.orderTotalAmount}>
          {i18nInstance.t('pages.results.table.header.amount')}
        </TextOverflowWrapper>
      ),
      render(_t, { currency, orderTotalAmount }) {
        return (
          <TextOverflowWrapper>
            {orderTotalAmount &&
              `${currency} ${formatCurrencyNumber(orderTotalAmount)}`}
          </TextOverflowWrapper>
        )
      },
      key: SEARCH_FIELD.orderTotalAmount,
    },
    leadPassenger: {
      width: '5%',
      title: (
        <TextOverflowWrapper data-test-id={SEARCH_FIELD.leadPassenger}>
          {i18nInstance.t('pages.results.table.header.leadPassenger')}
        </TextOverflowWrapper>
      ),
      render(_t, { products }) {
        const firstPassenger = products?.[0]?.travel?.passengers?.[0]
        const fullName = `${firstPassenger?.passengerFirstName ?? ''} ${
          firstPassenger?.passengerLastName ?? ''
        }`.trim()

        return (
          <TextOverflowWrapper
            maxWidth={SHOPPER_AND_PASSENGER_MAX_WIDTH}
            title={
              fullName.length > SHOPPER_AND_PASSENGER_CHAR_LIMIT
                ? fullName
                : undefined
            }
            data-test-id="leadPassengerName"
          >
            {fullName}
          </TextOverflowWrapper>
        )
      },
      key: SEARCH_FIELD.leadPassenger,
    },
    departurePortCode: {
      width: '5%',
      title: (
        <TextOverflowWrapper data-test-id={SEARCH_FIELD.departurePortCode}>
          {i18nInstance.t('pages.results.table.header.departureAirport')}
        </TextOverflowWrapper>
      ),
      render(_t, { products }) {
        return (
          <TextOverflowWrapper>
            {products?.[0]?.travel?.airlineTripDetail?.departurePortCode}
          </TextOverflowWrapper>
        )
      },
      key: SEARCH_FIELD.departurePortCode,
    },
    arrivalPortCode: {
      width: '5%',
      title: (
        <TextOverflowWrapper data-test-id={SEARCH_FIELD.arrivalPortCode}>
          {i18nInstance.t('pages.results.table.header.arrivalAirport')}{' '}
          <Tooltip
            overlayStyle={{ whiteSpace: 'pre-line' }}
            title={
              <span data-test-id="arrivalPortCode">
                {i18nInstance.t('pages.results.table.tooltip.arrivalAirport')}
              </span>
            }
            arrow={{ pointAtCenter: true }}
            placement="topRight"
          >
            <QuestionCircleOutlined
              data-test-id="arrivalAirportTooltip"
              data-analytics-id="arrival-airport-tooltip"
              className={styles.arrivalAirportTooltip}
            />
          </Tooltip>
        </TextOverflowWrapper>
      ),
      render(_t, { products }) {
        return (
          <TextOverflowWrapper>
            {products?.[0]?.travel?.airlineTripDetail?.arrivalPortCode}
          </TextOverflowWrapper>
        )
      },
      key: SEARCH_FIELD.arrivalPortCode,
    },
    shippingMethod: {
      width: '5%',
      title: (
        <TextOverflowWrapper data-test-id={SEARCH_FIELD.shippingMethod}>
          {i18nInstance.t('pages.results.table.header.shippingMethod')}
        </TextOverflowWrapper>
      ),
      render(_t, filters) {
        const shippingMethods = [
          ...new Set(filters.shipments.map(displayShippingMethod)),
        ].sort()

        return (
          <TextOverflowWrapper>
            {shippingMethods.slice(0, 2).join(', ')}
            <AdditionalValues values={shippingMethods} />
          </TextOverflowWrapper>
        )
      },
      key: SEARCH_FIELD.shippingMethod,
    },
    sellerId: {
      width: '5%',
      title: (
        <TextOverflowWrapper data-test-id={SEARCH_FIELD.sellerId}>
          {i18nInstance.t('pages.results.table.header.sellerId')}
        </TextOverflowWrapper>
      ),
      onCell: () => ({ onClick: popoverOnClickHandler }),
      render(_t, filters) {
        const ids = filters.sellers.map((seller) => seller.sellerId ?? '')

        return (
          <TextOverflowWrapper>
            {ids[0]}
            <AdditionalValues
              values={ids}
              total={1}
              title={i18nInstance.t('pages.results.columnSelect.keys.sellerId')}
              displayType="popover"
            />
          </TextOverflowWrapper>
        )
      },
      key: SEARCH_FIELD.sellerId,
    },
    sellerParentEntity: {
      width: '5%',
      title: (
        <TextOverflowWrapper data-test-id={SEARCH_FIELD.sellerParentEntity}>
          {i18nInstance.t('pages.results.table.header.sellerParentEntity')}
        </TextOverflowWrapper>
      ),
      onCell: () => ({ onClick: popoverOnClickHandler }),
      render(_t, filters) {
        const entities = filters.sellers.map(
          (seller) => seller.parentEntity ?? ''
        )

        return (
          <TextOverflowWrapper>
            {entities[0]}
            <AdditionalValues
              values={entities}
              total={1}
              title={i18nInstance.t(
                'pages.results.columnSelect.keys.sellerParentEntity'
              )}
              displayType="popover"
            />
          </TextOverflowWrapper>
        )
      },
      key: SEARCH_FIELD.sellerParentEntity,
    },
    sellerName: {
      width: '5%',
      title: (
        <TextOverflowWrapper data-test-id={SEARCH_FIELD.sellerName}>
          {i18nInstance.t('pages.results.table.header.sellerName')}
        </TextOverflowWrapper>
      ),
      onCell: () => ({ onClick: popoverOnClickHandler }),
      render(_t, filters) {
        const names = filters.sellers.map((seller) => seller.name ?? '')

        return (
          <TextOverflowWrapper>
            {names[0]}
            <AdditionalValues
              values={names}
              total={1}
              title={i18nInstance.t(
                'pages.results.columnSelect.keys.sellerName'
              )}
              displayType="popover"
            />
          </TextOverflowWrapper>
        )
      },
      key: SEARCH_FIELD.sellerName,
    },
    authorizationGatewayStatus: {
      width: '5%',
      title: (
        <TextOverflowWrapper
          data-test-id={SEARCH_FIELD.authorizationGatewayStatus}
        >
          {i18nInstance.t('pages.results.table.header.authStatus')}
        </TextOverflowWrapper>
      ),
      render(_t, { authorizationGatewayStatus }) {
        return (
          <TextOverflowWrapper>
            {authorizationGatewayStatus &&
              i18nInstance.t(
                `filters.advanced.authorizationGatewayStatus.${authorizationGatewayStatus}`
              )}
          </TextOverflowWrapper>
        )
      },
      key: SEARCH_FIELD.authorizationGatewayStatus,
    },
    investigationReviewDisposition: {
      width: '5%',
      title: (
        <TextOverflowWrapper
          data-test-id={SEARCH_FIELD.investigationReviewDisposition}
        >
          {i18nInstance.t('pages.results.table.header.caseFlag')}
        </TextOverflowWrapper>
      ),
      render(_t, { investigationReviewDisposition }) {
        if (investigationReviewDisposition === INV_REVIEW_DISPOSITION.NONE) {
          return null
        }

        return (
          <TextOverflowWrapper>
            <LegacyIcon
              className={styles.reviewIcon}
              type={
                investigationReviewDisposition === INV_REVIEW_DISPOSITION.GOOD
                  ? 'like'
                  : 'dislike'
              }
            />{' '}
            {investigationReviewDisposition &&
              i18n.t(
                `pages.results.table.caseFlag.${investigationReviewDisposition}`
              )}
          </TextOverflowWrapper>
        )
      },
      key: SEARCH_FIELD.investigationReviewDisposition,
    },
    caseStatus: {
      width: '5%',
      title: (
        <TextOverflowWrapper data-test-id={SEARCH_FIELD.caseStatus}>
          {i18nInstance.t('pages.results.table.header.caseStatus')}
        </TextOverflowWrapper>
      ),
      render(_t, { caseStatus }) {
        return (
          <TextOverflowWrapper>
            {caseStatus &&
              i18nInstance.t(`filters.advanced.caseStatus.${caseStatus}`)}
          </TextOverflowWrapper>
        )
      },
      key: SEARCH_FIELD.caseStatus,
    },
    caseType: {
      width: '5%',
      title: (
        <TextOverflowWrapper data-test-id={SEARCH_FIELD.caseType}>
          {i18nInstance.t('pages.results.table.header.caseType')}
        </TextOverflowWrapper>
      ),
      render(_t, { caseType }) {
        return (
          <TextOverflowWrapper>
            {caseType &&
              i18nInstance.t(`filters.advanced.caseType.${caseType}`)}
          </TextOverflowWrapper>
        )
      },
      key: SEARCH_FIELD.caseType,
    },
    checkoutToken: {
      width: '5%',
      title: (
        <TextOverflowWrapper data-test-id={SEARCH_FIELD.checkoutToken}>
          {i18nInstance.t('pages.results.table.header.checkoutToken')}
        </TextOverflowWrapper>
      ),
      render(_t, { checkoutToken }) {
        return (
          <TextOverflowWrapper
            title={checkoutToken}
            maxWidth={smallSize ? 80 : 150}
          >
            {checkoutToken}
          </TextOverflowWrapper>
        )
      },
      key: SEARCH_FIELD.checkoutToken,
    },
    isTestInvestigation: {
      width: '5%',
      render(_t, { isTestInvestigation }) {
        if (!isTestInvestigation) {
          return null
        }

        return (
          <TextOverflowWrapper maxWidth={30}>
            <Tooltip title={i18nInstance.t('pages.results.table.testCase')}>
              <ExperimentFilled
                aria-label={i18nInstance.t('pages.results.table.testCase')}
                style={{ fontSize: '16px', color: colorSlate }}
              />
            </Tooltip>
          </TextOverflowWrapper>
        )
      },
      key: SEARCH_FIELD.isTestInvestigation,
    },

    // Fixed Right Columns
    claimStatus: {
      width: '5%',
      title: (
        <TextOverflowWrapper data-test-id={SEARCH_FIELD.claimStatus}>
          {i18nInstance.t('pages.results.table.header.claimStatus')}
        </TextOverflowWrapper>
      ),
      render(_t, { claims }) {
        if (!claims.length) {
          return null
        }

        const [{ disposition, status }, ...rest] = claims

        const claimStatus = i18nInstance.t(
          `filters.claimStatus.${disposition ?? status}`
        )

        return (
          <TextOverflowWrapper>
            {rest.length
              ? `${i18nInstance.t('pages.results.table.andXMore', {
                  claimStatus,
                  count: rest.length,
                })}`
              : claimStatus}
          </TextOverflowWrapper>
        )
      },
      key: SEARCH_FIELD.claimStatus,
      fixed: hiddenCols.size > 4 ? false : !smallSize && 'right',
    },
    moreOptions: {
      key: 'moreOptions',
      render(_t, caseDetails) {
        return <MoreOptionsFlyout caseDetails={caseDetails} />
      },
      onCell: () => ({
        onClick: (e) => {
          // AntD does not have the event type correctly types, so we need to tell TS it is getting a HTML element
          const eventTarget = e.target as HTMLElement
          if (eventTarget.localName.includes('td')) {
            return undefined
          }

          return e.stopPropagation()
        },
      }),
      width: 28,
      fixed: 'right',
    },
  }

  const columns: Array<ColumnProps<InvSummaryViewInfo>> = [
    columnsConfigs.recommendation,
    columnsConfigs.guaranteeDisposition,
    columnsConfigs.signifydScore,
    columnsConfigs.investigationId,
    columnsConfigs.orderId,
    ...(hasAirlineOrders ? [columnsConfigs.recordLocator] : []),
    columnsConfigs.guaranteeRecommendedAction,
    columnsConfigs.returnDecisionCheckpointAction,
    ...(hasAirlineOrders ? [] : [columnsConfigs.recipientFullName]),
    columnsConfigs.normalizedPurchaseCreatedAt,
    columnsConfigs.orderTotalAmount,
    ...(hasAirlineOrders
      ? [
          columnsConfigs.leadPassenger,
          columnsConfigs.departurePortCode,
          columnsConfigs.arrivalPortCode,
        ]
      : []),
    ...(hasAirlineOrders ? [] : [columnsConfigs.shippingMethod]),
    ...(hasEventTicketingOrders
      ? [
          columnsConfigs.eventId,
          columnsConfigs.eventName,
          columnsConfigs.eventType,
          columnsConfigs.eventVenueName,
        ]
      : []),
    columnsConfigs.sellerId,
    columnsConfigs.sellerParentEntity,
    columnsConfigs.sellerName,
    columnsConfigs.authorizationGatewayStatus,
    columnsConfigs.investigationReviewDisposition,
    columnsConfigs.caseStatus,
    columnsConfigs.caseType,
    columnsConfigs.checkoutToken,
    columnsConfigs.isTestInvestigation,
    columnsConfigs.claimStatus,
    columnsConfigs.moreOptions,
  ]

  const filteredColumns = columns.filter(
    ({ key }) => !hiddenCols.has(key as SEARCH_FIELD)
  )

  return filteredColumns.map((col: ColumnProps<InvSummaryViewInfo>) => {
    if (sortableColumns.includes(col.key as SEARCH_FIELD)) {
      col.sorter = true
    }

    col.sortOrder = sort.by === col.key ? sort.order : undefined

    return col
  })
}
