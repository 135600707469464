import { FC, memo } from 'react'
import { Card, Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { CaseOrder, InvestigationInfo } from '@signifyd/http'
import { ErrorBoundary, T4, Space, useIsThirdGen } from '@signifyd/components'
import { CaseEntriesResolvedState } from 'store/currentCase/types/case.entries.types'
import { isTransportAirline } from 'core/utils/isTransportAirline/isTransportAirline'
import isEventTicketing from 'core/utils/isEventTicketing'
import GoogleMap from '../../components/GoogleMap'
import PaymentSummary from './PaymentSummary'
import AccountSummary from './AccountSummary'
import ShippingSummary from './ShippingSummary'
import OrderSummary from './OrderSummary'
import styles from './CaseSummary.less'
import PaymentSummaryThirdGen from './PaymentSummaryThirdGen'

export interface CaseSummaryProps {
  orderDetails: CaseOrder | null
  caseDetails: InvestigationInfo
  caseEntries: CaseEntriesResolvedState
}

export const CaseSummary: FC<CaseSummaryProps> = ({
  orderDetails,
  caseDetails,
  caseEntries,
}) => {
  const { t } = useTranslation()
  const { physicalLocations } = caseEntries

  const isAirline = isTransportAirline(caseDetails.products)
  const isEvent = isEventTicketing(caseDetails.products)

  const shouldDisplayShippingSummary = !isAirline && !isEvent

  const isThirdGen = useIsThirdGen()

  if (!orderDetails) {
    return null
  }

  return (
    <Card
      title={<T4 className={styles.title}>{t('caseSummary.title')}</T4>}
      size="small"
    >
      <Row gutter={[32, 0]}>
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <ErrorBoundary
            className={styles.errorBoundary}
            message={t('errorBoundaries.paymentSummary')}
          >
            {isThirdGen ? (
              <PaymentSummaryThirdGen
                orderDetails={orderDetails}
                caseDetails={caseDetails}
              />
            ) : (
              <PaymentSummary
                orderDetails={orderDetails}
                caseDetails={caseDetails}
              />
            )}
          </ErrorBoundary>
        </Col>

        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <ErrorBoundary
            className={styles.errorBoundary}
            message={t('errorBoundaries.locationDetails')}
          >
            <div className={styles.GoogleMapContainer}>
              <GoogleMap
                physicalLocations={physicalLocations}
                showResetButton={isAirline}
              />
              {!isAirline && (
                <a href="#locations" target="_self">
                  {t('caseSummary.order.viewAllLocations')}
                </a>
              )}
            </div>
          </ErrorBoundary>
        </Col>
      </Row>
      <Space size={24} />
      <Row gutter={[32, 0]}>
        {shouldDisplayShippingSummary && (
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <ErrorBoundary
              className={styles.errorBoundary}
              message={t('errorBoundaries.shippingSummary')}
            >
              <ShippingSummary caseDetails={caseDetails} />
            </ErrorBoundary>
          </Col>
        )}

        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <ErrorBoundary
            className={styles.errorBoundary}
            message={t('errorBoundaries.accountSummary')}
          >
            <AccountSummary
              orderDetails={orderDetails}
              caseDetails={caseDetails}
            />
          </ErrorBoundary>
        </Col>

        <Col span={24}>
          <ErrorBoundary
            className={styles.errorBoundary}
            message={t('errorBoundaries.orderSummary')}
          >
            <OrderSummary caseDetails={caseDetails} />
          </ErrorBoundary>
        </Col>
      </Row>
    </Card>
  )
}

export default memo(CaseSummary)
