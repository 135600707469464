import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { message } from 'antd'
import {
  createReroute,
  CreateRerouteRequest,
  CreateRerouteResponse,
  InvestigationInfo,
} from '@signifyd/http'
import { useTranslation } from 'react-i18next'
import { toRerouteRequest } from 'store/caseActions/utils'
import { useStoreActions } from 'store'
import { Address } from 'store/caseActions/types/updateAddress.types'
import { ActionState } from 'core/hooks/useCanUpdateAddress'
import { parseAPIErrorMsg } from '../utils/parseAPIErrorMsg'

const translationPrefix = 'store.caseActions.updateAddress'

export const useUpdateAddress = (
  actionState: ActionState
): UseMutationResult<
  CreateRerouteResponse,
  unknown,
  {
    caseDetails: InvestigationInfo
    addresses: Array<Address>
  },
  unknown
> => {
  const { t } = useTranslation()
  const { refreshCurrentCase } = useStoreActions(
    (actions) => actions.currentCase
  )

  const { disabled, hidden, disabledReason } = actionState

  return useMutation({
    mutationFn: async (requestPayload: {
      caseDetails: InvestigationInfo
      addresses: Array<Address>
    }) => {
      if (disabled || hidden) {
        throw new Error(disabledReason || t(`${translationPrefix}.notAllowed`))
      }

      const rerouteRequest: CreateRerouteRequest = toRerouteRequest(
        requestPayload.addresses,
        requestPayload.caseDetails
      )
      const { data } = await createReroute(
        rerouteRequest,
        requestPayload.caseDetails.teamId
      )

      return data
    },
    onSuccess: () => {
      refreshCurrentCase()
      message.success(t(`${translationPrefix}.apiSuccess`))
    },
    onError: (error) => {
      message.error(
        parseAPIErrorMsg(error) || t(`${translationPrefix}.apiFailure`)
      )
    },
  })
}
