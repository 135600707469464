import { useTranslation } from 'react-i18next'
import { Button } from 'antd'
import { InvestigationInfo } from '@signifyd/http'
import CopyWrapper from 'core/components/CopyWrapper'
import { useToggle } from 'core/utils/useToggle'
import { getShowHideItems } from 'pages/CaseReviewPage/containers/OrderDetails/OrderDetails.utils'
import { DescriptionsItemType } from 'antd/es/descriptions'
import { CaseEntriesResolvedState } from 'store/currentCase/types/case.entries.types'
import { isTransportAirline } from 'core/utils/isTransportAirline/isTransportAirline'
import DeepLinkDropdown from '../components/DeepLinkDropdown'
import {
  MENU_SECTION,
  MENU_SECTION_TYPE,
} from '../components/DeepLinkDropdown/DeepLinkDropdown.types'
import styles from '../CaseDetails.less'

const useAirportLocations = (
  caseDetails: InvestigationInfo | null,
  caseEntries?: CaseEntriesResolvedState | null
): Array<DescriptionsItemType> => {
  const { t } = useTranslation()

  const airportLocations =
    caseEntries?.physicalLocations.filter(({ role }) =>
      role.includes('flightLeg')
    ) ?? []

  const isAirlineOrder = isTransportAirline(caseDetails?.products ?? [])

  const { value: showAll, toggle: toggleShowAll } = useToggle()

  const { maxCountItems, itemsToShow } = getShowHideItems({
    items: airportLocations,
    showAll,
  })

  if (
    !caseDetails ||
    !caseEntries ||
    !isAirlineOrder ||
    !airportLocations.length
  ) {
    return []
  }

  return [
    ...itemsToShow.map((airport, index) => ({
      label: index === 0 && t('pages.caseReview.details.airportLocations'),
      children: (
        <CopyWrapper text={airport.fullAddress} className={styles.fullText}>
          <DeepLinkDropdown
            section={MENU_SECTION.airportLocations}
            type={MENU_SECTION_TYPE.airport}
            value={airport.fullAddress ?? ''}
          >
            {airport.fullAddress}
          </DeepLinkDropdown>
        </CopyWrapper>
      ),
    })),
    !!maxCountItems && {
      label: '',
      children: (
        <Button
          className={styles.showAllButton}
          onClick={toggleShowAll}
          type="link"
          data-analytics-id={`${
            showAll ? 'show-all' : 'show-less'
          }-airline-locations`}
        >
          {showAll
            ? t('pages.caseReview.details.showLess')
            : t('pages.caseReview.details.showAll')}
        </Button>
      ),
    },
  ] as Array<DescriptionsItemType>
}

export default useAirportLocations
