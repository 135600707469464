import { FC } from 'react'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import styles from './ShowAllButton.less'

interface ShowAllButtonProps {
  showAll: boolean
  toggleShowAll: () => void
  dataAnalyticsId: string
}

const ShowAllButton: FC<ShowAllButtonProps> = ({
  showAll,
  toggleShowAll,
  dataAnalyticsId,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'pages.caseReview.details',
  })

  return (
    <Button
      className={styles.showAllButton}
      onClick={toggleShowAll}
      type="link"
      data-analytics-id={dataAnalyticsId}
    >
      {showAll ? t('showLess') : t('showAll')}
    </Button>
  )
}

export default ShowAllButton
