import { FC } from 'react'
import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { COMMON_MODAL_PROPS } from 'core/constants'
import { useIsThirdGen } from '@signifyd/components'

interface Props {
  isLoading: boolean
  visible: boolean
  closeModal: () => void
  onOk: () => void
}

const CancelGuaranteeModal: FC<Props> = ({
  isLoading,
  visible,
  closeModal,
  onOk,
}) => {
  const { t } = useTranslation()
  const isThirdGen = useIsThirdGen()

  return (
    <Modal
      {...COMMON_MODAL_PROPS}
      title={t('caseActions.cancelGuarantee.modalTitle')}
      open={visible}
      confirmLoading={isLoading}
      okType={isThirdGen ? 'primary' : 'link'}
      okText={
        <span data-test-id="cancelGuaranteeModalButton">
          {t('caseActions.cancelGuarantee.okText')}
        </span>
      }
      cancelText={
        <span data-test-id="keepGuaranteeModalButton">
          {t('caseActions.cancelGuarantee.cancelText')}
        </span>
      }
      onOk={onOk}
      onCancel={closeModal}
    >
      {t('caseActions.cancelGuarantee.helpText')}
    </Modal>
  )
}

export default CancelGuaranteeModal
