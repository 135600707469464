import { FC, ReactNode } from 'react'
import { Dropdown } from 'antd'
import {
  EkataIcon,
  GoogleMapIcon,
  GoogleSearchIcon,
  LinkedinIcon,
  PiplIcon,
  SigLogoSmall,
  WhitepagesIcon,
  IconProps,
} from '@signifyd/components'
import { USER_FEATURE } from '@signifyd/http'
import { useStoreState } from 'store'
import DeepLinkMenuItems from 'core/components/DeepLinkMenuItems'
import { useCaseEntries } from 'core/hooks/useCaseEntries'
import getDeepLinksEventAddress from 'pages/CaseReviewPage/containers/OrderDetails/components/CaseDetails/components/DeepLinkDropdown/getDeepLinks/getDeepLinks.eventAddress'
import {
  getDeepLinksOccupants,
  getDeepLinksAccounts,
  getDeepLinksAddress,
  getDeepLinksNetwork,
  getDeepLinksPeople,
  getDeepLinksPhone,
} from './getDeepLinks'
import {
  MENU_SECTION,
  MENU_SECTION_TYPE,
  MENU_ICON,
  DeepLinkFactory,
} from './DeepLinkDropdown.types'
import styles from './DeepLinkDropdown.less'
import getDeepLinksAirportLocations from './getDeepLinks/getDeepLinks.airportLocations'

const menuSections = new Map<MENU_SECTION, DeepLinkFactory>([
  [MENU_SECTION.occupants, getDeepLinksOccupants],
  [MENU_SECTION.accounts, getDeepLinksAccounts],
  [MENU_SECTION.address, getDeepLinksAddress],
  [MENU_SECTION.network, getDeepLinksNetwork],
  [MENU_SECTION.people, getDeepLinksPeople],
  [MENU_SECTION.phone, getDeepLinksPhone],
  [MENU_SECTION.airportLocations, getDeepLinksAirportLocations],
  [MENU_SECTION.eventTicketing, getDeepLinksEventAddress],
])

const menuIcons = new Map<MENU_ICON, FC<IconProps>>([
  [MENU_ICON.GoogleMapIcon, GoogleMapIcon],
  [MENU_ICON.GoogleSearchIcon, GoogleSearchIcon],
  [MENU_ICON.SigLogoSmall, SigLogoSmall],
  [MENU_ICON.PiplIcon, PiplIcon],
  [MENU_ICON.EkataIcon, EkataIcon],
  [MENU_ICON.WhitepagesIcon, WhitepagesIcon],
  [MENU_ICON.LinkedinIcon, LinkedinIcon],
])

interface Props {
  section: MENU_SECTION
  type?: MENU_SECTION_TYPE
  children: ReactNode
  value?: string
}

const DeepLinkDropdown: FC<Props> = ({ section, type, children, value }) => {
  const caseDetails = useStoreState((state) => state.currentCase.details)
  const { data: caseEntries } = useCaseEntries(caseDetails?.investigationId)
  const currentUser = useStoreState((state) => state.user.currentUser)
  const hasPowersearch = !!currentUser?.features[USER_FEATURE.POWER_SEARCH]

  if (!caseDetails || !caseEntries) {
    return null
  }

  const menuSection = menuSections.get(section)
  const menuItemsData = menuSection
    ? menuSection({ caseEntries, type, value, caseDetails, hasPowersearch })
    : []

  return menuItemsData.length ? (
    <Dropdown
      data-analytics-id={`deeplink-link-section-${section}`}
      getPopupContainer={(trigger) => trigger as HTMLElement}
      menu={{ items: DeepLinkMenuItems(menuItemsData, menuIcons) }}
      trigger={['click']}
      placement="bottomRight"
      // Actually make dropdown respect placement given the getPopupContainer is a smaller element https://github.com/ant-design/ant-design/issues/12070
      align={{ overflow: { adjustX: false, adjustY: false } }}
    >
      <span className={styles.sigDropdownTrigger}>{children}</span>
    </Dropdown>
  ) : (
    <>{children}</>
  )
}

export default DeepLinkDropdown
