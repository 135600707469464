import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Descriptions } from 'antd'
import CopyWrapper from 'core/components/CopyWrapper'
import { useToggle } from 'core/utils/useToggle'
import { getShowHideItems } from 'pages/CaseReviewPage/containers/OrderDetails/OrderDetails.utils'
import ShowAllButton from 'pages/CaseReviewPage/containers/OrderDetails/components/CaseDetails/Locations/ShowAllButton'

const { Item: DescriptionItem } = Descriptions

interface EventVenueLocationsProps {
  eventVenueNames: Array<string>
}

const EventVenueLocations: FC<EventVenueLocationsProps> = ({
  eventVenueNames,
}) => {
  const { t } = useTranslation()

  const { value: showAll, toggle: toggleShowAll } = useToggle()

  const { maxCountItems, itemsToShow } = getShowHideItems({
    items: eventVenueNames,
    showAll,
  })

  return (
    <Descriptions colon={false} size="small">
      {itemsToShow.map((eventVenueName, index) => (
        <DescriptionItem
          key={eventVenueName}
          label={
            index === 0 &&
            t('pages.caseReview.details.eventLocation', {
              count: eventVenueNames.length,
            })
          }
        >
          <CopyWrapper text={eventVenueName}>{eventVenueName}</CopyWrapper>
        </DescriptionItem>
      ))}
      {!!maxCountItems && (
        <DescriptionItem>
          <ShowAllButton
            showAll={showAll}
            toggleShowAll={toggleShowAll}
            dataAnalyticsId={`${
              showAll ? 'show-all' : 'show-less'
            }-event-ticketing-locations`}
          />
        </DescriptionItem>
      )}
    </Descriptions>
  )
}

export default EventVenueLocations
