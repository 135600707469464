import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Flex, Popconfirm } from 'antd'
import { T4, Text } from '@signifyd/components'
import { isEqual, cloneDeep } from 'lodash'
import styles from './TableColumnSelectDrawer.less'
import { ColumnConfig, getDefaultColumnConfigs } from './columns'

interface Props {
  columns: Array<ColumnConfig>
  setColumns: (columns: Array<ColumnConfig>) => void
  clearChanges: () => void
  applyChanges: () => void
  hasChanges: boolean
  conditionallyClear: () => void
  hasAirlineOrders?: boolean
}

const TableColumnSelectFooter: FC<Props> = ({
  columns,
  setColumns,
  clearChanges,
  applyChanges,
  hasChanges,
  conditionallyClear,
  hasAirlineOrders,
}) => {
  const { t } = useTranslation()

  const isDefaultSet = useMemo(() => {
    return isEqual(columns, getDefaultColumnConfigs())
  }, [columns])

  return (
    <Flex align="center" justify="space-between">
      <Button
        data-test-id="resetColumnsButton"
        onClick={() => {
          setColumns(cloneDeep(getDefaultColumnConfigs(hasAirlineOrders)))
        }}
        type="link"
        disabled={isDefaultSet}
      >
        {t('pages.results.columnSelect.close.reset')}
      </Button>
      <div>
        <Popconfirm
          overlayClassName="sig-popconfirm"
          icon={null}
          disabled={hasChanges}
          title={
            <div data-test-id="drawer-close-confirm">
              <T4>{t('pages.results.columnSelect.close.confirmTitle')}</T4>
              <Text type="secondary">
                {t('pages.results.columnSelect.close.confirmText')}
              </Text>
            </div>
          }
          onConfirm={clearChanges}
          okText={t('pages.results.columnSelect.close.confirmClose')}
          cancelText={t('pages.results.columnSelect.close.no')}
        >
          <Button
            className={styles.cancelButton}
            data-test-id="cancel-button-bottom"
            onClick={conditionallyClear}
          >
            {t('pages.results.columnSelect.cancel')}
          </Button>
        </Popconfirm>
        <Button
          onClick={applyChanges}
          disabled={hasChanges}
          data-test-id="ok-apply-button"
          type="primary"
        >
          {t('pages.results.columnSelect.apply')}
        </Button>
      </div>
    </Flex>
  )
}

export default TableColumnSelectFooter
