import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { HomeOutlined } from '@ant-design/icons'
import { House, useIsThirdGen } from '@signifyd/components'
import { useToggle } from 'core/utils/useToggle'
import { useUpdateAddress } from 'core/hooks/useUpdateAddress'
import { useStoreState } from 'store'
import { InvestigationInfo } from '@signifyd/http'
import { useCanUpdateAddress } from 'core/hooks/useCanUpdateAddress'
import UpdateAddressModal from '../../containers/UpdateAddressModal'
import ActionButton from '../ActionButton'
import ActionIconButton from '../ActionIconButton/ActionIconButton'

interface Props {
  caseDetails: InvestigationInfo
}

export const UpdateAddressButton: FC<Props> = ({ caseDetails }) => {
  const isThirdGen = useIsThirdGen()
  const { t } = useTranslation(undefined, {
    keyPrefix: 'caseActions.updateAddress',
  })
  const {
    value: modalVisible,
    enable: showModal,
    disable: hideModal,
  } = useToggle()

  const user = useStoreState((state) => state.user.currentUser)

  const actionState = useCanUpdateAddress(caseDetails, user)

  const { mutateAsync: updateAddress, isLoading } =
    useUpdateAddress(actionState)

  return (
    <>
      {isThirdGen ? (
        <ActionIconButton
          onClick={showModal}
          actionState={actionState}
          isLoading={isLoading}
          Icon={House}
          tooltip={t('updateAddressButton')}
        />
      ) : (
        <ActionButton
          onClick={showModal}
          actionState={actionState}
          isLoading={isLoading}
          icon={<HomeOutlined />}
          text={t('updateAddressButton')}
          tooltip={t('updateAddressButton')}
        />
      )}

      <UpdateAddressModal
        caseDetails={caseDetails!}
        visible={modalVisible}
        closeModal={hideModal}
        onSave={updateAddress}
      />
    </>
  )
}

export default UpdateAddressButton
